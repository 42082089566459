import {
  AddJournalEntryInput,
  API,
  BalanceType,
  GstInfo,
  PayeeType,
  RecurrenceFieldsFragmentDoc,
  RecurrenceInput,
  useAddJournalEntryMutation,
} from 'api';
import { useAuth } from 'context';
import { useCurrentUser } from 'hooks/useCurrentUser';
import { useAllErrors } from 'hooks/useErrorNotifications';
import { useNotification } from 'hooks/useNotification';
import { UploadError, UploadSuccess } from 'hooks/useUploadFiles';
import { GLCategory } from 'pages/accounting/categories';
import { parseCurrencyFloat, safeSum } from 'system';
import { useAccountingContext } from '../context';
import { useGlAccounts } from './useGlAccounts';
import { useGlMapping } from './useGlMapping';

export type ExpenseLine = {
  id?: string;
  glId: string;
  unitId?: string;
  description: string;
  billAmounts: string;
};

export type ExpenseFormFields = {
  id?: string;
  payeeId: string;
  payee: PayeeType;
  glId: string;
  ownerId: string;
  propertyId: string;
  posted: string;
  ref: string;
  lines: ExpenseLine[];
  recurrence?: RecurrenceInput;
  noteText?: string;
  applyGstHst: boolean;
  gst?: string;
};

export const expenseOffsetCategories: GLCategory[] = ['bank', 'creditCard'];

export const useEnterExpense = () => {
  const { sendNotification } = useNotification();
  const { currentUser } = useCurrentUser();
  const { mappedGlId } = useGlMapping();
  const { accountId } = useAuth();
  // TODO: Need a way to have a generic `ownerFor` to get the owner of any given asset
  const { ownerFor } = useAccountingContext();
  const { findGlAccount } = useGlAccounts();
  const [addJournalEntryMutation, { error: jeError }] = useAddJournalEntryMutation();
  useAllErrors(jeError);
  const gstId = mappedGlId('gstHstPayable');

  const enterExpense = async (
    values: ExpenseFormFields,
    filesToUpload: File[],
    uploadFiles: ({ id }: { id: string }) => Promise<{
      successfulUploads: UploadSuccess[];
      failedUploads: UploadError[];
    }>
  ) => {
    const glAccount = findGlAccount(values.glId);
    const total = values.lines.reduce(
      (result, line) => safeSum(result, parseCurrencyFloat(line.billAmounts)),
      values.applyGstHst ? parseCurrencyFloat(values.gst) : 0
    );

    if (values.applyGstHst && !gstId) {
      sendNotification('GST/HST account mapping not found, check Financial Settings', 'error');
      return;
    }

    const firstUnitId = values.lines[0].unitId;
    const jeInput: AddJournalEntryInput = {
      posted: values.posted,
      lines: [
        {
          glId: values.glId,
          amount: glAccount?.balanceType === BalanceType.Credit ? total : -total,
          description: values.lines[0].description,
          propertyId: values.propertyId,
          ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
          ref: values.ref,
          ...(firstUnitId && { unitId: firstUnitId }),
          payeeId: values.payeeId,
          payee: values.payee,
        },
        ...(values.applyGstHst && gstId
          ? [
              {
                ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
                propertyId: values.propertyId,
                ref: values.ref,
                glId: gstId,
                ...(firstUnitId && { unitId: firstUnitId }),
                amount: -parseCurrencyFloat(values.gst),
                description: values.lines[0].description,
                gstInfo: GstInfo.Line106,
                payeeId: values.payeeId,
                payee: values.payee,
              },
            ]
          : []),
        ...values.lines.map(({ glId, unitId, description, billAmounts }) => {
          const lineGlAccount = findGlAccount(glId);
          const parsedAmount = parseCurrencyFloat(billAmounts);
          const amount =
            lineGlAccount?.balanceType === BalanceType.Debit ? parsedAmount : -parsedAmount;
          return {
            glId,
            amount,
            description,
            propertyId: values.propertyId,
            ownerId: values.ownerId ?? ownerFor(values.propertyId).id,
            ref: values.ref,
            ...(unitId && { unitId }),
            payeeId: values.payeeId,
            payee: values.payee,
          };
        }),
      ],
      notes:
        values?.noteText && currentUser?.name
          ? [{ createdName: currentUser.name, text: values.noteText }]
          : undefined,
      ...(values.recurrence ? { recurrence: values.recurrence } : {}),
    };

    let newjeId: string | undefined = '';

    try {
      const result = await addJournalEntryMutation({
        variables: { input: jeInput },
        update(cache, { data }) {
          const id = cache.identify({ accountId, __typename: 'Books' });
          if (data?.addJournalEntry?.success && data?.addJournalEntry.recurrence) {
            cache.modify({
              id,
              fields: {
                recurrences: (existing = []) => [
                  ...existing,
                  cache.writeFragment({
                    data: data.addJournalEntry.recurrence,
                    fragment: RecurrenceFieldsFragmentDoc,
                    fragmentName: API.Fragment.RecurrenceFields,
                  }),
                ],
              },
            });
          }
        },
      });

      newjeId = result.data?.addJournalEntry?.journalEntries?.[0]?.jeId;

      sendNotification('New expense has been added', 'success');
    } catch (e) {
      console.error(e);
      sendNotification('Error adding expense', 'error');
    }

    try {
      if (newjeId && filesToUpload.length > 0) {
        await uploadFiles({ id: newjeId });
      }
    } catch (e) {
      console.error(e);
      sendNotification('There was an error uploading files', 'error');
    }
  };

  return {
    enterExpense,
  };
};
