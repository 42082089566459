import { BrowserStorageCache as Cache } from '@aws-amplify/cache';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ifDifferent } from 'system';

type PersistentStateOptions = {
  validatorFn?: (cachedValue: unknown) => boolean;
  resetInitialState?: boolean;
};

export enum PersistentState {
  pageSize = 'pageSize',
  parkingPageSize = 'parkingPageSize',
  storagePageSize = 'storagePageSize',
  density = 'density',
  selectedPropertyIds = 'selectedPropertyIds',
  listGrid = 'listGrid',
  propertyOwner = 'propertyOwner',
  selectedTeam = 'selectedTeam',
  approvalsTab = 'approvalsTab',
  subAccountFilter = 'subAccountFilter',
  propertyFinancialFilter = 'propertyFinancialFilter',
}

const getKeyWithEnv = (key: PersistentState | string) => {
  const appEnv = process.env.REACT_APP_ENV ?? 'prod';
  return appEnv.toLowerCase() === 'prod' ? key : `${appEnv}-${key}`;
};

export const usePersistentState = <S = undefined>(
  key: PersistentState | string,
  initialState: S | ((cachedState?: S | undefined) => S),
  options: PersistentStateOptions = {}
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const validatorFnRef = useRef(options.validatorFn);
  const initialStateRef = useRef(initialState);

  const [state, _setState] = useState<S>(() => {
    const keyWithEnv = getKeyWithEnv(key);
    let cachedState;
    try {
      cachedState = JSON.parse(Cache.getItem(keyWithEnv)) as S;
      if (validatorFnRef.current && !validatorFnRef.current(cachedState)) {
        cachedState = undefined;
      }
    } catch {
      /* no op */
    }

    const initialStateValue =
      typeof initialStateRef.current === 'function'
        ? (initialStateRef.current as () => S)()
        : initialStateRef.current;

    return options.resetInitialState ? initialStateValue : (cachedState ?? initialStateValue);
  });

  const setStateRef = useRef(false);

  const setState = useCallback((value: React.SetStateAction<S>) => {
    setStateRef.current = true;
    _setState(value);
  }, []);

  useEffect(() => {
    const keyWithEnv = getKeyWithEnv(key);
    let cachedState;
    try {
      cachedState = JSON.parse(Cache.getItem(keyWithEnv)) as S;
      if (validatorFnRef.current && !validatorFnRef.current(cachedState)) {
        cachedState = undefined;
      }
    } catch {
      console.warn('No cache value found', { key });
      cachedState = undefined;
    }

    _setState(
      ifDifferent(
        cachedState ??
          (initialStateRef.current && typeof initialStateRef.current === 'function'
            ? (initialStateRef.current as () => S)()
            : initialStateRef.current)
      )
    );
  }, [key]);

  useEffect(() => {
    if (setStateRef.current) {
      const keyWithEnv = getKeyWithEnv(key);
      Cache.setItem(keyWithEnv, JSON.stringify(state));
      setStateRef.current = false;
    }
  }, [key, state]);

  return [state, setState];
};
