import { Place } from 'hooks/useGoogleAutocompletePredictions';
import { get } from 'lodash';
import { Country, Provinces, TimezonesByCountry, UnionToIntersection } from 'system/types';

export const geography = {
  AU: {
    code: 'AU',
    name: 'Australia',
    timezone: 'Australia/Sydney',
    provinceLabel: 'State/Territory',
    postalLabel: 'Postcode',
    provinces: {
      ACT: { code: 'ACT', timezone: 'Australia/Sydney', name: 'Australian Capital Territory' },
      NSW: { code: 'NSW', timezone: 'Australia/Sydney', name: 'New South Wales' },
      NT: { code: 'NT', timezone: 'Australia/Darwin', name: 'Northern Territory' },
      QLD: { code: 'QLD', timezone: 'Australia/Brisbane', name: 'Queensland' },
      SA: { code: 'SA', timezone: 'Australia/Adelaide', name: 'South Australia' },
      TAS: { code: 'TAS', timezone: 'Australia/Hobart', name: 'Tasmania' },
      VIC: { code: 'VIC', timezone: 'Australia/Melbourne', name: 'Victoria' },
      WA: { code: 'WA', timezone: 'Australia/Perth', name: 'Western Australia' },
    },
  },
  BH: {
    code: 'BH',
    name: 'Bahrain',
    timezone: 'Asia/Bahrain',
    provinceLabel: 'Governorate',
    postalLabel: 'Postal Code',
    provinces: {
      'Capital Governorate': { code: '13', timezone: 'Asia/Bahrain', name: 'Capital Governorate' },
      'Muharraq Governorate': {
        code: '14',
        timezone: 'Asia/Bahrain',
        name: 'Muharraq Governorate',
      },
      'Northern Governorate': {
        code: '15',
        timezone: 'Asia/Bahrain',
        name: 'Northern Governorate',
      },
      'Southern Governorate': {
        code: '16',
        timezone: 'Asia/Bahrain',
        name: 'Southern Governorate',
      },
      'Central Governorate': { code: '17', timezone: 'Asia/Bahrain', name: 'Central Governorate' },
    },
  },
  BR: {
    code: 'BR',
    name: 'Brazil',
    timezone: 'America/Sao_Paulo',
    provinceLabel: 'State',
    postalLabel: 'Postal Code',
    provinces: {
      AC: { code: 'AC', timezone: 'America/Rio_Branco', name: 'Acre' },
      AL: { code: 'AL', timezone: 'America/Maceio', name: 'Alagoas' },
      AP: { code: 'AP', timezone: 'America/Belem', name: 'Amapá' },
      AM: { code: 'AM', timezone: 'America/Manaus', name: 'Amazonas' },
      BA: { code: 'BA', timezone: 'America/Bahia', name: 'Bahia' },
      CE: { code: 'CE', timezone: 'America/Fortaleza', name: 'Ceará' },
      DF: { code: 'DF', timezone: 'America/Sao_Paulo', name: 'Distrito Federal' },
      ES: { code: 'ES', timezone: 'America/Sao_Paulo', name: 'Espírito Santo' },
      GO: { code: 'GO', timezone: 'America/Sao_Paulo', name: 'Goiás' },
      MA: { code: 'MA', timezone: 'America/Fortaleza', name: 'Maranhão' },
      MT: { code: 'MT', timezone: 'America/Cuiaba', name: 'Mato Grosso' },
      MS: { code: 'MS', timezone: 'America/Campo_Grande', name: 'Mato Grosso do Sul' },
      MG: { code: 'MG', timezone: 'America/Sao_Paulo', name: 'Minas Gerais' },
      PA: { code: 'PA', timezone: 'America/Belem', name: 'Pará' },
      PB: { code: 'PB', timezone: 'America/Fortaleza', name: 'Paraíba' },
      PR: { code: 'PR', timezone: 'America/Sao_Paulo', name: 'Paraná' },
      PE: { code: 'PE', timezone: 'America/Recife', name: 'Pernambuco' },
      PI: { code: 'PI', timezone: 'America/Fortaleza', name: 'Piauí' },
      RJ: { code: 'RJ', timezone: 'America/Sao_Paulo', name: 'Rio de Janeiro' },
      RN: { code: 'RN', timezone: 'America/Fortaleza', name: 'Rio Grande do Norte' },
      RS: { code: 'RS', timezone: 'America/Sao_Paulo', name: 'Rio Grande do Sul' },
      RO: { code: 'RO', timezone: 'America/Porto_Velho', name: 'Rondônia' },
      RR: { code: 'RR', timezone: 'America/Boa_Vista', name: 'Roraima' },
      SC: { code: 'SC', timezone: 'America/Sao_Paulo', name: 'Santa Catarina' },
      SP: { code: 'SP', timezone: 'America/Sao_Paulo', name: 'São Paulo' },
      SE: { code: 'SE', timezone: 'America/Maceio', name: 'Sergipe' },
      TO: { code: 'TO', timezone: 'America/Araguaina', name: 'Tocantins' },
    },
  },
  CA: {
    code: 'CA',
    name: 'Canada',
    timezone: 'America/Edmonton',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      AB: { code: 'AB', timezone: 'America/Edmonton', name: 'Alberta' },
      BC: { code: 'BC', timezone: 'America/Vancouver', name: 'British Columbia' },
      MB: { code: 'MB', timezone: 'America/Winnipeg', name: 'Manitoba' },
      NB: { code: 'NB', timezone: 'America/Halifax', name: 'New Brunswick' },
      NL: { code: 'NL', timezone: 'America/St_Johns', name: 'Newfoundland and Labrador' },
      NS: { code: 'NS', timezone: 'America/Halifax', name: 'Nova Scotia' },
      NT: { code: 'NT', timezone: 'America/Edmonton', name: 'Northwest Territories' },
      NU: { code: 'NU', timezone: 'America/Winnipeg', name: 'Nunavut' },
      ON: { code: 'ON', timezone: 'America/Toronto', name: 'Ontario' },
      PE: { code: 'PE', timezone: 'America/Halifax', name: 'Prince Edward Island' },
      QC: { code: 'QC', timezone: 'America/Toronto', name: 'Quebec' },
      SK: { code: 'SK', timezone: 'America/Regina', name: 'Saskatchewan' },
      YT: { code: 'YT', timezone: 'America/Edmonton', name: 'Yukon' },
    },
  },
  CU: {
    code: 'CU',
    name: 'Cuba',
    timezone: 'America/Havana',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      'Pinar del Río': { code: '01', timezone: 'America/Havana', name: 'Pinar del Río' },
      Artemisa: { code: '02', timezone: 'America/Havana', name: 'Artemisa' },
      'La Habana': { code: '03', timezone: 'America/Havana', name: 'La Habana' },
      Mayabeque: { code: '04', timezone: 'America/Havana', name: 'Mayabeque' },
      Matanzas: { code: '05', timezone: 'America/Havana', name: 'Matanzas' },
      Cienfuegos: { code: '06', timezone: 'America/Havana', name: 'Cienfuegos' },
      'Villa Clara': { code: '07', timezone: 'America/Havana', name: 'Villa Clara' },
      'Sancti Spíritus': { code: '08', timezone: 'America/Havana', name: 'Sancti Spíritus' },
      'Ciego de Ávila': { code: '09', timezone: 'America/Havana', name: 'Ciego de Ávila' },
      Camagüey: { code: '10', timezone: 'America/Havana', name: 'Camagüey' },
      'Las Tunas': { code: '11', timezone: 'America/Havana', name: 'Las Tunas' },
      Holguín: { code: '12', timezone: 'America/Havana', name: 'Holguín' },
      Granma: { code: '13', timezone: 'America/Havana', name: 'Granma' },
      'Santiago de Cuba': { code: '14', timezone: 'America/Havana', name: 'Santiago de Cuba' },
      Guantánamo: { code: '15', timezone: 'America/Havana', name: 'Guantánamo' },
      'Isla de la Juventud': {
        code: '16',
        timezone: 'America/Havana',
        name: 'Isla de la Juventud',
      },
    },
  },
  CN: {
    code: 'CN',
    name: 'China',
    timezone: 'Asia/Shanghai',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Anhui: { code: 'Anhui', timezone: 'Asia/Shanghai', name: 'Anhui' },
      Beijing: { code: 'Beijing', timezone: 'Asia/Shanghai', name: 'Beijing' },
      Chongqing: { code: 'Chongqing', timezone: 'Asia/Shanghai', name: 'Chongqing' },
      Fujian: { code: 'Fujian', timezone: 'Asia/Shanghai', name: 'Fujian' },
      Gansu: { code: 'Gansu', timezone: 'Asia/Shanghai', name: 'Gansu' },
      Guangdong: { code: 'Guangdong', timezone: 'Asia/Shanghai', name: 'Guangdong' },
      Guangxi: { code: 'Guangxi', timezone: 'Asia/Shanghai', name: 'Guangxi' },
      Guizhou: { code: 'Guizhou', timezone: 'Asia/Shanghai', name: 'Guizhou' },
      Hainan: { code: 'Hainan', timezone: 'Asia/Shanghai', name: 'Hainan' },
      Hebei: { code: 'Hebei', timezone: 'Asia/Shanghai', name: 'Hebei' },
      Henan: { code: 'Henan', timezone: 'Asia/Shanghai', name: 'Henan' },
      Heilongjiang: { code: 'Heilongjiang', timezone: 'Asia/Shanghai', name: 'Heilongjiang' },
      Hubei: { code: 'Hubei', timezone: 'Asia/Shanghai', name: 'Hubei' },
      Hunan: { code: 'Hunan', timezone: 'Asia/Shanghai', name: 'Hunan' },
      Jilin: { code: 'Jilin', timezone: 'Asia/Shanghai', name: 'Jilin' },
      Jiangsu: { code: 'Jiangsu', timezone: 'Asia/Shanghai', name: 'Jiangsu' },
      Jiangxi: { code: 'Jiangxi', timezone: 'Asia/Shanghai', name: 'Jiangxi' },
      Liaoning: { code: 'Liaoning', timezone: 'Asia/Shanghai', name: 'Liaoning' },
      InnerMongolia: { code: 'InnerMongolia', timezone: 'Asia/Shanghai', name: 'Inner Mongolia' },
      Ningxia: { code: 'Ningxia', timezone: 'Asia/Shanghai', name: 'Ningxia' },
      Qinghai: { code: 'Qinghai', timezone: 'Asia/Shanghai', name: 'Qinghai' },
      Sichuan: { code: 'Sichuan', timezone: 'Asia/Shanghai', name: 'Sichuan' },
      'Shan Dong Sheng': { code: 'Shan Dong Sheng', timezone: 'Asia/Shanghai', name: 'Shandong' },
      'Shang Hai Shi': { code: 'Shang Hai Shi', timezone: 'Asia/Shanghai', name: 'Shanghai' },
      Shaanxi: { code: 'Shaanxi', timezone: 'Asia/Shanghai', name: 'Shaanxi' },
      Shanxi: { code: 'Shanxi', timezone: 'Asia/Shanghai', name: 'Shanxi' },
      Tianjin: { code: 'Tianjin', timezone: 'Asia/Shanghai', name: 'Tianjin' },
      Xinjiang: { code: 'Xinjiang', timezone: 'Asia/Shanghai', name: 'Xinjiang' },
      Tibet: { code: 'Tibet', timezone: 'Asia/Shanghai', name: 'Tibet' },
      Yunnan: { code: 'Yunnan', timezone: 'Asia/Shanghai', name: 'Yunnan' },
      Zhejiang: { code: 'Zhejiang', timezone: 'Asia/Shanghai', name: 'Zhejiang' },
    },
  },
  CO: {
    code: 'CO',
    name: 'Colombia',
    timezone: 'America/Bogota',
    provinceLabel: 'Department',
    postalLabel: 'Postal Code',
    provinces: {
      Amazonas: { code: 'Amazonas', timezone: 'America/Bogota', name: 'Amazonas' },
      Antioquia: { code: 'Antioquia', timezone: 'America/Bogota', name: 'Antioquia' },
      Arauca: { code: 'Arauca', timezone: 'America/Bogota', name: 'Arauca' },
      Atlántico: { code: 'Atlántico', timezone: 'America/Bogota', name: 'Atlántico' },
      Bolívar: { code: 'Bolívar', timezone: 'America/Bogota', name: 'Bolívar' },
      Boyacá: { code: 'Boyacá', timezone: 'America/Bogota', name: 'Boyacá' },
      Caldas: { code: 'Caldas', timezone: 'America/Bogota', name: 'Caldas' },
      Caquetá: { code: 'Caquetá', timezone: 'America/Bogota', name: 'Caquetá' },
      Casanare: { code: 'Casanare', timezone: 'America/Bogota', name: 'Casanare' },
      Cauca: { code: 'Cauca', timezone: 'America/Bogota', name: 'Cauca' },
      Cesar: { code: 'Cesar', timezone: 'America/Bogota', name: 'Cesar' },
      Chocó: { code: 'Chocó', timezone: 'America/Bogota', name: 'Chocó' },
      Córdoba: { code: 'Córdoba', timezone: 'America/Bogota', name: 'Córdoba' },
      Cundinamarca: { code: 'Cundinamarca', timezone: 'America/Bogota', name: 'Cundinamarca' },
      Guainía: { code: 'Guainía', timezone: 'America/Bogota', name: 'Guainía' },
      Guaviare: { code: 'Guaviare', timezone: 'America/Bogota', name: 'Guaviare' },
      Huila: { code: 'Huila', timezone: 'America/Bogota', name: 'Huila' },
      'La Guajira': { code: 'La Guajira', timezone: 'America/Bogota', name: 'La Guajira' },
      Magdalena: { code: 'Magdalena', timezone: 'America/Bogota', name: 'Magdalena' },
      Meta: { code: 'Meta', timezone: 'America/Bogota', name: 'Meta' },
      Nariño: { code: 'Nariño', timezone: 'America/Bogota', name: 'Nariño' },
      'Norte de Santander': {
        code: 'Norte de Santander',
        timezone: 'America/Bogota',
        name: 'Norte de Santander',
      },
      Putumayo: { code: 'Putumayo', timezone: 'America/Bogota', name: 'Putumayo' },
      Quindío: { code: 'Quindío', timezone: 'America/Bogota', name: 'Quindío' },
      Risaralda: { code: 'Risaralda', timezone: 'America/Bogota', name: 'Risaralda' },
      'San Andrés y Providencia': {
        code: 'San Andrés y Providencia',
        timezone: 'America/Bogota',
        name: 'San Andrés y Providencia',
      },
      Santander: { code: 'Santander', timezone: 'America/Bogota', name: 'Santander' },
      Sucre: { code: 'Sucre', timezone: 'America/Bogota', name: 'Sucre' },
      Tolima: { code: 'Tolima', timezone: 'America/Bogota', name: 'Tolima' },
      'Valle del Cauca': {
        code: 'Valle del Cauca',
        timezone: 'America/Bogota',
        name: 'Valle del Cauca',
      },
      Vaupés: { code: 'Vaupés', timezone: 'America/Bogota', name: 'Vaupés' },
      Vichada: { code: 'Vichada', timezone: 'America/Bogota', name: 'Vichada' },
    },
  },
  DK: {
    code: 'DK',
    name: 'Denmark',
    timezone: 'Europe/Copenhagen',
    provinceLabel: 'Region',
    postalLabel: 'Postal Code',
    provinces: {
      'Capital Region of Denmark': {
        code: '84',
        timezone: 'Europe/Copenhagen',
        name: 'Capital Region of Denmark',
      },
      'Central Denmark Region': {
        code: '82',
        timezone: 'Europe/Copenhagen',
        name: 'Central Denmark Region',
      },
      'North Denmark Region': {
        code: '81',
        timezone: 'Europe/Copenhagen',
        name: 'North Denmark Region',
      },
      'Region Zealand': { code: '85', timezone: 'Europe/Copenhagen', name: 'Region Zealand' },
      'Region of Southern Denmark': {
        code: '83',
        timezone: 'Europe/Copenhagen',
        name: 'Region of Southern Denmark',
      },
    },
  },
  DE: {
    code: 'DE',
    name: 'Germany',
    timezone: 'Europe/Berlin',
    provinceLabel: 'State',
    postalLabel: 'Postal Code',
    provinces: {
      'Baden-Württemberg': { code: 'BW', timezone: 'Europe/Berlin', name: 'Baden-Württemberg' },
      Bavaria: { code: 'BY', timezone: 'Europe/Berlin', name: 'Bavaria' },
      Berlin: { code: 'BE', timezone: 'Europe/Berlin', name: 'Berlin' },
      Brandenburg: { code: 'BB', timezone: 'Europe/Berlin', name: 'Brandenburg' },
      Bremen: { code: 'HB', timezone: 'Europe/Berlin', name: 'Bremen' },
      Hamburg: { code: 'HH', timezone: 'Europe/Berlin', name: 'Hamburg' },
      Hesse: { code: 'HE', timezone: 'Europe/Berlin', name: 'Hesse' },
      'Mecklenburg-Western Pomerania': {
        code: 'MV',
        timezone: 'Europe/Berlin',
        name: 'Mecklenburg-Western Pomerania',
      },
      'Lower Saxony': { code: 'NI', timezone: 'Europe/Berlin', name: 'Lower Saxony' },
      'North Rhine-Westphalia': {
        code: 'NW',
        timezone: 'Europe/Berlin',
        name: 'North Rhine-Westphalia',
      },
      'Rhineland-Palatinate': {
        code: 'RP',
        timezone: 'Europe/Berlin',
        name: 'Rhineland-Palatinate',
      },
      Saarland: { code: 'SL', timezone: 'Europe/Berlin', name: 'Saarland' },
      Saxony: { code: 'SN', timezone: 'Europe/Berlin', name: 'Saxony' },
      'Saxony-Anhalt': { code: 'ST', timezone: 'Europe/Berlin', name: 'Saxony-Anhalt' },
      'Schleswig-Holstein': { code: 'SH', timezone: 'Europe/Berlin', name: 'Schleswig-Holstein' },
      Thuringia: { code: 'TH', timezone: 'Europe/Berlin', name: 'Thuringia' },
    },
  },
  GR: {
    code: 'GR',
    name: 'Greece',
    timezone: 'Europe/Athens',
    provinceLabel: 'Region',
    postalLabel: 'Postal Code',
    provinces: {
      'Eastern Macedonia and Thrace': {
        code: 'GR-A',
        timezone: 'Europe/Athens',
        name: 'Eastern Macedonia and Thrace',
      },
      'Central Macedonia': { code: 'GR-B', timezone: 'Europe/Athens', name: 'Central Macedonia' },
      'Western Macedonia': { code: 'GR-C', timezone: 'Europe/Athens', name: 'Western Macedonia' },
      Epirus: { code: 'GR-D', timezone: 'Europe/Athens', name: 'Epirus' },
      Thessaly: { code: 'GR-E', timezone: 'Europe/Athens', name: 'Thessaly' },
      'Ionian Islands': { code: 'GR-F', timezone: 'Europe/Athens', name: 'Ionian Islands' },
      'Western Greece': { code: 'GR-G', timezone: 'Europe/Athens', name: 'Western Greece' },
      'Central Greece': { code: 'GR-H', timezone: 'Europe/Athens', name: 'Central Greece' },
      Attica: { code: 'GR-I', timezone: 'Europe/Athens', name: 'Attica' },
      Peloponnese: { code: 'GR-J', timezone: 'Europe/Athens', name: 'Peloponnese' },
      'North Aegean': { code: 'GR-K', timezone: 'Europe/Athens', name: 'North Aegean' },
      'South Aegean': { code: 'GR-L', timezone: 'Europe/Athens', name: 'South Aegean' },
      Crete: { code: 'GR-M', timezone: 'Europe/Athens', name: 'Crete' },
    },
  },
  HU: {
    code: 'HU',
    name: 'Hungary',
    timezone: 'Europe/Budapest',
    provinceLabel: 'County',
    postalLabel: 'Postal Code',
    provinces: {
      Baranya: { code: 'BA', timezone: 'Europe/Budapest', name: 'Baranya' },
      'Bács-Kiskun': { code: 'BK', timezone: 'Europe/Budapest', name: 'Bács-Kiskun' },
      Békés: { code: 'BE', timezone: 'Europe/Budapest', name: 'Békés' },
      'Borsod-Abaúj-Zemplén': {
        code: 'BS',
        timezone: 'Europe/Budapest',
        name: 'Borsod-Abaúj-Zemplén',
      },
      Budapest: { code: 'BU', timezone: 'Europe/Budapest', name: 'Budapest' },
      'Csongrád-Csanád': { code: 'CS', timezone: 'Europe/Budapest', name: 'Csongrád-Csanád' },
      Fejér: { code: 'FE', timezone: 'Europe/Budapest', name: 'Fejér' },
      'Győr-Moson-Sopron': { code: 'GS', timezone: 'Europe/Budapest', name: 'Győr-Moson-Sopron' },
      'Hajdú-Bihar': { code: 'HB', timezone: 'Europe/Budapest', name: 'Hajdú-Bihar' },
      Heves: { code: 'HE', timezone: 'Europe/Budapest', name: 'Heves' },
      'Jász-Nagykun-Szolnok': {
        code: 'JE',
        timezone: 'Europe/Budapest',
        name: 'Jász-Nagykun-Szolnok',
      },
      'Komárom-Esztergom': { code: 'KE', timezone: 'Europe/Budapest', name: 'Komárom-Esztergom' },
      Nógrád: { code: 'NO', timezone: 'Europe/Budapest', name: 'Nógrád' },
      Pest: { code: 'PE', timezone: 'Europe/Budapest', name: 'Pest' },
      Somogy: { code: 'SO', timezone: 'Europe/Budapest', name: 'Somogy' },
      'Szabolcs-Szatmár-Bereg': {
        code: 'SZ',
        timezone: 'Europe/Budapest',
        name: 'Szabolcs-Szatmár-Bereg',
      },
      Tolna: { code: 'TO', timezone: 'Europe/Budapest', name: 'Tolna' },
      Vas: { code: 'VA', timezone: 'Europe/Budapest', name: 'Vas' },
      Veszprém: { code: 'VE', timezone: 'Europe/Budapest', name: 'Veszprém' },
      Zala: { code: 'ZA', timezone: 'Europe/Budapest', name: 'Zala' },
    },
  },
  KE: {
    code: 'KE',
    name: 'Kenya',
    timezone: 'Africa/Nairobi',
    provinceLabel: 'County',
    postalLabel: 'Postal Code',
    provinces: {
      Mombasa: { code: 'Mombasa', timezone: 'Africa/Nairobi', name: 'Mombasa' },
      Kwale: { code: 'Kwale', timezone: 'Africa/Nairobi', name: 'Kwale' },
      Kilifi: { code: 'Kilifi', timezone: 'Africa/Nairobi', name: 'Kilifi' },
      'Tana River': { code: 'Tana River', timezone: 'Africa/Nairobi', name: 'Tana River' },
      Lamu: { code: 'Lamu', timezone: 'Africa/Nairobi', name: 'Lamu' },
      'Taita-Taveta': { code: 'Taita-Taveta', timezone: 'Africa/Nairobi', name: 'Taita-Taveta' },
      Garissa: { code: 'Garissa', timezone: 'Africa/Nairobi', name: 'Garissa' },
      Wajir: { code: 'Wajir', timezone: 'Africa/Nairobi', name: 'Wajir' },
      Mandera: { code: 'Mandera', timezone: 'Africa/Nairobi', name: 'Mandera' },
      Marsabit: { code: 'Marsabit', timezone: 'Africa/Nairobi', name: 'Marsabit' },
      Isiolo: { code: 'Isiolo', timezone: 'Africa/Nairobi', name: 'Isiolo' },
      Meru: { code: 'Meru', timezone: 'Africa/Nairobi', name: 'Meru' },
      'Tharaka-Nithi': { code: 'Tharaka-Nithi', timezone: 'Africa/Nairobi', name: 'Tharaka-Nithi' },
      Embu: { code: 'Embu', timezone: 'Africa/Nairobi', name: 'Embu' },
      Kitui: { code: 'Kitui', timezone: 'Africa/Nairobi', name: 'Kitui' },
      Machakos: { code: 'Machakos', timezone: 'Africa/Nairobi', name: 'Machakos' },
      Makueni: { code: 'Makueni', timezone: 'Africa/Nairobi', name: 'Makueni' },
      Nyandarua: { code: 'Nyandarua', timezone: 'Africa/Nairobi', name: 'Nyandarua' },
      Nyeri: { code: 'Nyeri', timezone: 'Africa/Nairobi', name: 'Nyeri' },
      Kirinyaga: { code: 'Kirinyaga', timezone: 'Africa/Nairobi', name: 'Kirinyaga' },
      "Murang'a": { code: "Murang'a", timezone: 'Africa/Nairobi', name: "Murang'a" },
      Kiambu: { code: 'Kiambu', timezone: 'Africa/Nairobi', name: 'Kiambu' },
      Turkana: { code: 'Turkana', timezone: 'Africa/Nairobi', name: 'Turkana' },
      'West Pokot': { code: 'West Pokot', timezone: 'Africa/Nairobi', name: 'West Pokot' },
      Samburu: { code: 'Samburu', timezone: 'Africa/Nairobi', name: 'Samburu' },
      'Trans-Nzoia': { code: 'Trans-Nzoia', timezone: 'Africa/Nairobi', name: 'Trans-Nzoia' },
      'Uasin Gishu': { code: 'Uasin Gishu', timezone: 'Africa/Nairobi', name: 'Uasin Gishu' },
      'Elgeyo-Marakwet': {
        code: 'Elgeyo-Marakwet',
        timezone: 'Africa/Nairobi',
        name: 'Elgeyo-Marakwet',
      },
      Nandi: { code: 'Nandi', timezone: 'Africa/Nairobi', name: 'Nandi' },
      Baringo: { code: 'Baringo', timezone: 'Africa/Nairobi', name: 'Baringo' },
      Laikipia: { code: 'Laikipia', timezone: 'Africa/Nairobi', name: 'Laikipia' },
      Nakuru: { code: 'Nakuru', timezone: 'Africa/Nairobi', name: 'Nakuru' },
      Narok: { code: 'Narok', timezone: 'Africa/Nairobi', name: 'Narok' },
      Kajiado: { code: 'Kajiado', timezone: 'Africa/Nairobi', name: 'Kajiado' },
      Kericho: { code: 'Kericho', timezone: 'Africa/Nairobi', name: 'Kericho' },
      Bomet: { code: 'Bomet', timezone: 'Africa/Nairobi', name: 'Bomet' },
      Kakamega: { code: 'Kakamega', timezone: 'Africa/Nairobi', name: 'Kakamega' },
      Vihiga: { code: 'Vihiga', timezone: 'Africa/Nairobi', name: 'Vihiga' },
      Bungoma: { code: 'Bungoma', timezone: 'Africa/Nairobi', name: 'Bungoma' },
      Busia: { code: 'Busia', timezone: 'Africa/Nairobi', name: 'Busia' },
      Siaya: { code: 'Siaya', timezone: 'Africa/Nairobi', name: 'Siaya' },
      Kisumu: { code: 'Kisumu', timezone: 'Africa/Nairobi', name: 'Kisumu' },
      'Homa Bay': { code: 'Homa Bay', timezone: 'Africa/Nairobi', name: 'Homa Bay' },
      Migori: { code: 'Migori', timezone: 'Africa/Nairobi', name: 'Migori' },
      Kisii: { code: 'Kisii', timezone: 'Africa/Nairobi', name: 'Kisii' },
      Nyamira: { code: 'Nyamira', timezone: 'Africa/Nairobi', name: 'Nyamira' },
      Nairobi: { code: 'Nairobi', timezone: 'Africa/Nairobi', name: 'Nairobi' },
    },
  },
  PA: {
    code: 'PA',
    name: 'Panama',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    timezone: 'America/Panama',
    provinces: {
      'Bocas del Toro': { code: '01', name: 'Bocas del Toro', timezone: undefined },
      Cocle: { code: '02', name: 'Cocle', timezone: undefined },
      Colon: { code: '03', name: 'Colon', timezone: undefined },
      Chiriqui: { code: '04', name: 'Chiriqui', timezone: undefined },
      Darien: { code: '05', name: 'Darien', timezone: undefined },
      Herrera: { code: '06', name: 'Herrera', timezone: undefined },
      'Los Santos': { code: '07', name: 'Los Santos', timezone: undefined },
      Panama: { code: '08', name: 'Panama', timezone: undefined },
      Veraguas: { code: '09', name: 'Veraguas', timezone: undefined },
      'West Panama': { code: '10', name: 'West Panama', timezone: undefined },
      Embera: { code: 'EM', name: 'Embera', timezone: undefined },
      'Guna Yala': { code: 'KY', name: 'Guna Yala', timezone: undefined },
      'Ngabe-Bugle': { code: 'NB', name: 'Ngabe-Bugle', timezone: undefined },
      'Naso Tjer Di': { code: 'NT', name: 'Naso Tjer Di', timezone: undefined },
    },
  },
  SG: {
    code: 'SG',
    name: 'Singapore',
    timezone: 'Asia/Singapore',
    provinceLabel: 'Planning Area',
    postalLabel: 'Postal Code',
    provinces: {
      Singapore: { code: 'SG', timezone: 'Asia/Singapore', name: 'Singapore' },
    },
  },
  TW: {
    code: 'TW',
    name: 'Taiwan',
    timezone: 'Asia/Taipei',
    provinceLabel: 'County',
    postalLabel: 'Postal Code',
    provinces: {
      Chiayi: { code: 'CYI', timezone: 'Asia/Taipei', name: 'Chiayi' },
      Hsinchu: { code: 'HSQ', timezone: 'Asia/Taipei', name: 'Hsinchu' },
      Hualien: { code: 'HUA', timezone: 'Asia/Taipei', name: 'Hualien' },
      Kaohsiung: { code: 'KHH', timezone: 'Asia/Taipei', name: 'Kaohsiung' },
      Keelung: { code: 'KEE', timezone: 'Asia/Taipei', name: 'Keelung' },
      Kinmen: { code: 'KIN', timezone: 'Asia/Taipei', name: 'Kinmen' },
      Miaoli: { code: 'MIA', timezone: 'Asia/Taipei', name: 'Miaoli' },
      Nantou: { code: 'NAN', timezone: 'Asia/Taipei', name: 'Nantou' },
      Penghu: { code: 'PEF', timezone: 'Asia/Taipei', name: 'Penghu' },
      Pingtung: { code: 'PIF', timezone: 'Asia/Taipei', name: 'Pingtung' },
      NewTaipei: { code: 'TPH', timezone: 'Asia/Taipei', name: 'New Taipei' },
      Tainan: { code: 'TNN', timezone: 'Asia/Taipei', name: 'Tainan' },
      Taipei: { code: 'TPE', timezone: 'Asia/Taipei', name: 'Taipei' },
      Taitung: { code: 'TTN', timezone: 'Asia/Taipei', name: 'Taitung' },
      Taichung: { code: 'TXG', timezone: 'Asia/Taipei', name: 'Taichung' },
      Yunlin: { code: 'YUN', timezone: 'Asia/Taipei', name: 'Yunlin' },
    },
  },
  TR: {
    code: 'TR',
    name: 'Turkey',
    timezone: 'Europe/Istanbul',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Adana: { code: '01', timezone: 'Europe/Istanbul', name: 'Adana' },
      Adıyaman: { code: '02', timezone: 'Europe/Istanbul', name: 'Adıyaman' },
      Afyonkarahisar: { code: '03', timezone: 'Europe/Istanbul', name: 'Afyonkarahisar' },
      Ağrı: { code: '04', timezone: 'Europe/Istanbul', name: 'Ağrı' },
      Amasya: { code: '05', timezone: 'Europe/Istanbul', name: 'Amasya' },
      Ankara: { code: '06', timezone: 'Europe/Istanbul', name: 'Ankara' },
      Antalya: { code: '07', timezone: 'Europe/Istanbul', name: 'Antalya' },
      Artvin: { code: '08', timezone: 'Europe/Istanbul', name: 'Artvin' },
      Aydın: { code: '09', timezone: 'Europe/Istanbul', name: 'Aydın' },
      Balıkesir: { code: '10', timezone: 'Europe/Istanbul', name: 'Balıkesir' },
      Bilecik: { code: '11', timezone: 'Europe/Istanbul', name: 'Bilecik' },
      Bingöl: { code: '12', timezone: 'Europe/Istanbul', name: 'Bingöl' },
      Bitlis: { code: '13', timezone: 'Europe/Istanbul', name: 'Bitlis' },
      Bolu: { code: '14', timezone: 'Europe/Istanbul', name: 'Bolu' },
      Burdur: { code: '15', timezone: 'Europe/Istanbul', name: 'Burdur' },
      Bursa: { code: '16', timezone: 'Europe/Istanbul', name: 'Bursa' },
      Çanakkale: { code: '17', timezone: 'Europe/Istanbul', name: 'Çanakkale' },
      Çankırı: { code: '18', timezone: 'Europe/Istanbul', name: 'Çankırı' },
      Çorum: { code: '19', timezone: 'Europe/Istanbul', name: 'Çorum' },
      Denizli: { code: '20', timezone: 'Europe/Istanbul', name: 'Denizli' },
      Diyarbakır: { code: '21', timezone: 'Europe/Istanbul', name: 'Diyarbakır' },
      Edirne: { code: '22', timezone: 'Europe/Istanbul', name: 'Edirne' },
      Elazığ: { code: '23', timezone: 'Europe/Istanbul', name: 'Elazığ' },
      Erzincan: { code: '24', timezone: 'Europe/Istanbul', name: 'Erzincan' },
      Erzurum: { code: '25', timezone: 'Europe/Istanbul', name: 'Erzurum' },
      Eskişehir: { code: '26', timezone: 'Europe/Istanbul', name: 'Eskişehir' },
      Gaziantep: { code: '27', timezone: 'Europe/Istanbul', name: 'Gaziantep' },
      Giresun: { code: '28', timezone: 'Europe/Istanbul', name: 'Giresun' },
      Gümüşhane: { code: '29', timezone: 'Europe/Istanbul', name: 'Gümüşhane' },
      Hakkâri: { code: '30', timezone: 'Europe/Istanbul', name: 'Hakkâri' },
      Hatay: { code: '31', timezone: 'Europe/Istanbul', name: 'Hatay' },
      Isparta: { code: '32', timezone: 'Europe/Istanbul', name: 'Isparta' },
      Mersin: { code: '33', timezone: 'Europe/Istanbul', name: 'Mersin' },
      Istanbul: { code: '34', timezone: 'Europe/Istanbul', name: 'Istanbul' },
      Izmir: { code: '35', timezone: 'Europe/Istanbul', name: 'Izmir' },
      Kars: { code: '36', timezone: 'Europe/Istanbul', name: 'Kars' },
      Kastamonu: { code: '37', timezone: 'Europe/Istanbul', name: 'Kastamonu' },
      Kayseri: { code: '38', timezone: 'Europe/Istanbul', name: 'Kayseri' },
      Kırklareli: { code: '39', timezone: 'Europe/Istanbul', name: 'Kırklareli' },
      Kırşehir: { code: '40', timezone: 'Europe/Istanbul', name: 'Kırşehir' },
      Kocaeli: { code: '41', timezone: 'Europe/Istanbul', name: 'Kocaeli' },
      Konya: { code: '42', timezone: 'Europe/Istanbul', name: 'Konya' },
      Kütahya: { code: '43', timezone: 'Europe/Istanbul', name: 'Kütahya' },
      Malatya: { code: '44', timezone: 'Europe/Istanbul', name: 'Malatya' },
      Manisa: { code: '45', timezone: 'Europe/Istanbul', name: 'Manisa' },
      Kahramanmaraş: { code: '46', timezone: 'Europe/Istanbul', name: 'Kahramanmaraş' },
      Mardin: { code: '47', timezone: 'Europe/Istanbul', name: 'Mardin' },
      Muğla: { code: '48', timezone: 'Europe/Istanbul', name: 'Muğla' },
      Muş: { code: '49', timezone: 'Europe/Istanbul', name: 'Muş' },
      Nevşehir: { code: '50', timezone: 'Europe/Istanbul', name: 'Nevşehir' },
      Niğde: { code: '51', timezone: 'Europe/Istanbul', name: 'Niğde' },
      Ordu: { code: '52', timezone: 'Europe/Istanbul', name: 'Ordu' },
      Rize: { code: '53', timezone: 'Europe/Istanbul', name: 'Rize' },
      Sakarya: { code: '54', timezone: 'Europe/Istanbul', name: 'Sakarya' },
      Samsun: { code: '55', timezone: 'Europe/Istanbul', name: 'Samsun' },
      Siirt: { code: '56', timezone: 'Europe/Istanbul', name: 'Siirt' },
      Sinop: { code: '57', timezone: 'Europe/Istanbul', name: 'Sinop' },
      Sivas: { code: '58', timezone: 'Europe/Istanbul', name: 'Sivas' },
      Tekirdağ: { code: '59', timezone: 'Europe/Istanbul', name: 'Tekirdağ' },
      Tokat: { code: '60', timezone: 'Europe/Istanbul', name: 'Tokat' },
      Trabzon: { code: '61', timezone: 'Europe/Istanbul', name: 'Trabzon' },
      Tunceli: { code: '62', timezone: 'Europe/Istanbul', name: 'Tunceli' },
      Şanlıurfa: { code: '63', timezone: 'Europe/Istanbul', name: 'Şanlıurfa' },
      Uşak: { code: '64', timezone: 'Europe/Istanbul', name: 'Uşak' },
      Van: { code: '65', timezone: 'Europe/Istanbul', name: 'Van' },
      Yozgat: { code: '66', timezone: 'Europe/Istanbul', name: 'Yozgat' },
      Zonguldak: { code: '67', timezone: 'Europe/Istanbul', name: 'Zonguldak' },
      Aksaray: { code: '68', timezone: 'Europe/Istanbul', name: 'Aksaray' },
      Bayburt: { code: '69', timezone: 'Europe/Istanbul', name: 'Bayburt' },
      Karaman: { code: '70', timezone: 'Europe/Istanbul', name: 'Karaman' },
      Kırıkkale: { code: '71', timezone: 'Europe/Istanbul', name: 'Kırıkkale' },
      Batman: { code: '72', timezone: 'Europe/Istanbul', name: 'Batman' },
      Şırnak: { code: '73', timezone: 'Europe/Istanbul', name: 'Şırnak' },
      Bartın: { code: '74', timezone: 'Europe/Istanbul', name: 'Bartın' },
      Ardahan: { code: '75', timezone: 'Europe/Istanbul', name: 'Ardahan' },
      Iğdır: { code: '76', timezone: 'Europe/Istanbul', name: 'Iğdır' },
      Yalova: { code: '77', timezone: 'Europe/Istanbul', name: 'Yalova' },
      Karabük: { code: '78', timezone: 'Europe/Istanbul', name: 'Karabük' },
      Kilis: { code: '79', timezone: 'Europe/Istanbul', name: 'Kilis' },
      Osmaniye: { code: '80', timezone: 'Europe/Istanbul', name: 'Osmaniye' },
      Düzce: { code: '81', timezone: 'Europe/Istanbul', name: 'Düzce' },
    },
  },
  AE: {
    code: 'AE',
    name: 'United Arab Emirates',
    timezone: 'Asia/Dubai',
    provinceLabel: 'Emirate',
    postalLabel: 'Postal Code',
    provinces: {
      AbuDhabi: { code: 'AZ', timezone: 'Asia/Dubai', name: 'Abu Dhabi' },
      Ajman: { code: 'AJ', timezone: 'Asia/Dubai', name: 'Ajman' },
      Dubai: { code: 'DU', timezone: 'Asia/Dubai', name: 'Dubai' },
      Fujairah: { code: 'FU', timezone: 'Asia/Dubai', name: 'Fujairah' },
      RasAlKhaimah: { code: 'RK', timezone: 'Asia/Dubai', name: 'Ras Al Khaimah' },
      Sharjah: { code: 'SH', timezone: 'Asia/Dubai', name: 'Sharjah' },
      UmmAlQuwain: { code: 'UA', timezone: 'Asia/Dubai', name: 'Umm Al Quwain' },
    },
  },
  US: {
    code: 'US',
    name: 'United States',
    timezone: 'America/Chicago',
    provinceLabel: 'State',
    postalLabel: 'Zip',
    provinces: {
      AL: { timezone: 'America/Chicago', code: 'AL', name: 'Alabama' },
      AK: { timezone: 'America/Anchorage', code: 'AK', name: 'Alaska' },
      AZ: { timezone: 'America/Phoenix', code: 'AZ', name: 'Arizona' },
      AR: { timezone: 'America/Chicago', code: 'AR', name: 'Arkansas' },
      CA: { timezone: 'America/Los_Angeles', code: 'CA', name: 'California' },
      CO: { timezone: 'America/Denver', code: 'CO', name: 'Colorado' },
      CT: { timezone: 'America/New_York', code: 'CT', name: 'Connecticut' },
      DE: { timezone: 'America/New_York', code: 'DE', name: 'Delaware' },
      FL: { timezone: 'America/New_York', code: 'FL', name: 'Florida' },
      GA: { timezone: 'America/New_York', code: 'GA', name: 'Georgia' },
      HI: { timezone: 'Pacific/Honolulu', code: 'HI', name: 'Hawaii' },
      ID: { timezone: 'America/Denver', code: 'ID', name: 'Idaho' },
      IL: { timezone: 'America/Chicago', code: 'IL', name: 'Illinois' },
      IN: { timezone: 'America/Indianapolis', code: 'IN', name: 'Indiana' },
      IA: { timezone: 'America/Chicago', code: 'IA', name: 'Iowa' },
      KS: { timezone: 'America/Chicago', code: 'KS', name: 'Kansas' },
      KY: { timezone: 'America/New_York', code: 'KY', name: 'Kentucky' },
      LA: { timezone: 'America/Chicago', code: 'LA', name: 'Louisiana' },
      ME: { timezone: 'America/New_York', code: 'ME', name: 'Maine' },
      MD: { timezone: 'America/New_York', code: 'MD', name: 'Maryland' },
      MA: { timezone: 'America/New_York', code: 'MA', name: 'Massachusetts' },
      MI: { timezone: 'America/New_York', code: 'MI', name: 'Michigan' },
      MN: { timezone: 'America/Chicago', code: 'MN', name: 'Minnesota' },
      MS: { timezone: 'America/Chicago', code: 'MS', name: 'Mississippi' },
      MO: { timezone: 'America/Chicago', code: 'MO', name: 'Missouri' },
      MT: { timezone: 'America/Denver', code: 'MT', name: 'Montana' },
      NE: { timezone: 'America/Chicago', code: 'NE', name: 'Nebraska' },
      NV: { timezone: 'America/Los_Angeles', code: 'NV', name: 'Nevada' },
      NH: { timezone: 'America/New_York', code: 'NH', name: 'New Hampshire' },
      NJ: { timezone: 'America/New_York', code: 'NJ', name: 'New Jersey' },
      NM: { timezone: 'America/Denver', code: 'NM', name: 'New Mexico' },
      NY: { timezone: 'America/New_York', code: 'NY', name: 'New York' },
      NC: { timezone: 'America/New_York', code: 'NC', name: 'North Carolina' },
      ND: { timezone: 'America/Chicago', code: 'ND', name: 'North Dakota' },
      OH: { timezone: 'America/New_York', code: 'OH', name: 'Ohio' },
      OK: { timezone: 'America/Chicago', code: 'OK', name: 'Oklahoma' },
      OR: { timezone: 'America/Los_Angeles', code: 'OR', name: 'Oregon' },
      PA: { timezone: 'America/New_York', code: 'PA', name: 'Pennsylvania' },
      RI: { timezone: 'America/New_York', code: 'RI', name: 'Rhode Island' },
      SC: { timezone: 'America/New_York', code: 'SC', name: 'South Carolina' },
      SD: { timezone: 'America/Chicago', code: 'SD', name: 'South Dakota' },
      TN: { timezone: 'America/Chicago', code: 'TN', name: 'Tennessee' },
      TX: { timezone: 'America/Chicago', code: 'TX', name: 'Texas' },
      UT: { timezone: 'America/Denver', code: 'UT', name: 'Utah' },
      VT: { timezone: 'America/New_York', code: 'VT', name: 'Vermont' },
      VA: { timezone: 'America/New_York', code: 'VA', name: 'Virginia' },
      WA: { timezone: 'America/Los_Angeles', code: 'WA', name: 'Washington' },
      WV: { timezone: 'America/New_York', code: 'WV', name: 'West Virginia' },
      WI: { timezone: 'America/Chicago', code: 'WI', name: 'Wisconsin' },
      WY: { timezone: 'America/Denver', code: 'WY', name: 'Wyoming' },
    },
  },
  IN: {
    code: 'IN',
    name: 'India',
    timezone: 'Asia/Kolkata',
    provinceLabel: 'State/Union Territory',
    postalLabel: 'Postal Code',
    provinces: {
      AP: { code: 'AP', timezone: 'Asia/Kolkata', name: 'Andhra Pradesh' },
      AR: { code: 'AR', timezone: 'Asia/Kolkata', name: 'Arunachal Pradesh' },
      AS: { code: 'AS', timezone: 'Asia/Kolkata', name: 'Assam' },
      BR: { code: 'BR', timezone: 'Asia/Kolkata', name: 'Bihar' },
      CT: { code: 'CT', timezone: 'Asia/Kolkata', name: 'Chhattisgarh' },
      GA: { code: 'GA', timezone: 'Asia/Kolkata', name: 'Goa' },
      GJ: { code: 'GJ', timezone: 'Asia/Kolkata', name: 'Gujarat' },
      HR: { code: 'HR', timezone: 'Asia/Kolkata', name: 'Haryana' },
      HP: { code: 'HP', timezone: 'Asia/Kolkata', name: 'Himachal Pradesh' },
      JH: { code: 'JH', timezone: 'Asia/Kolkata', name: 'Jharkhand' },
      KA: { code: 'KA', timezone: 'Asia/Kolkata', name: 'Karnataka' },
      KL: { code: 'KL', timezone: 'Asia/Kolkata', name: 'Kerala' },
      MP: { code: 'MP', timezone: 'Asia/Kolkata', name: 'Madhya Pradesh' },
      MH: { code: 'MH', timezone: 'Asia/Kolkata', name: 'Maharashtra' },
      MN: { code: 'MN', timezone: 'Asia/Kolkata', name: 'Manipur' },
      ML: { code: 'ML', timezone: 'Asia/Kolkata', name: 'Meghalaya' },
      MZ: { code: 'MZ', timezone: 'Asia/Kolkata', name: 'Mizoram' },
      NL: { code: 'NL', timezone: 'Asia/Kolkata', name: 'Nagaland' },
      OR: { code: 'OR', timezone: 'Asia/Kolkata', name: 'Odisha' },
      PB: { code: 'PB', timezone: 'Asia/Kolkata', name: 'Punjab' },
      RJ: { code: 'RJ', timezone: 'Asia/Kolkata', name: 'Rajasthan' },
      SK: { code: 'SK', timezone: 'Asia/Kolkata', name: 'Sikkim' },
      TN: { code: 'TN', timezone: 'Asia/Kolkata', name: 'Tamil Nadu' },
      TG: { code: 'TG', timezone: 'Asia/Kolkata', name: 'Telangana' },
      TR: { code: 'TR', timezone: 'Asia/Kolkata', name: 'Tripura' },
      UP: { code: 'UP', timezone: 'Asia/Kolkata', name: 'Uttar Pradesh' },
      UT: { code: 'UT', timezone: 'Asia/Kolkata', name: 'Uttarakhand' },
      WB: { code: 'WB', timezone: 'Asia/Kolkata', name: 'West Bengal' },
      AN: { code: 'AN', timezone: 'Asia/Kolkata', name: 'Andaman and Nicobar Islands' },
      CH: { code: 'CH', timezone: 'Asia/Kolkata', name: 'Chandigarh' },
      DH: {
        code: 'DH',
        timezone: 'Asia/Kolkata',
        name: 'Dadra and Nagar Haveli and Daman and Diu',
      },
      DL: { code: 'DL', timezone: 'Asia/Kolkata', name: 'Delhi' },
      JK: { code: 'JK', timezone: 'Asia/Kolkata', name: 'Jammu and Kashmir' },
      LA: { code: 'LA', timezone: 'Asia/Kolkata', name: 'Ladakh' },
      LD: { code: 'LD', timezone: 'Asia/Kolkata', name: 'Lakshadweep' },
      PY: { code: 'PY', timezone: 'Asia/Kolkata', name: 'Puducherry' },
    },
  },
  IE: {
    code: 'IE',
    name: 'Ireland',
    timezone: 'Europe/Dublin',
    provinceLabel: 'Province',
    postalLabel: 'Eircode',
    provinces: {
      Connacht: { code: 'CN', timezone: 'Europe/Dublin', name: 'Connacht' },
      Leinster: { code: 'LE', timezone: 'Europe/Dublin', name: 'Leinster' },
      Munster: { code: 'MU', timezone: 'Europe/Dublin', name: 'Munster' },
      Ulster: { code: 'UL', timezone: 'Europe/Dublin', name: 'Ulster' },
    },
  },
  JP: {
    code: 'JP',
    name: 'Japan',
    timezone: 'Asia/Tokyo',
    provinceLabel: 'Prefecture',
    postalLabel: 'Postal Code',
    provinces: {
      Aichi: { code: 'AIC', timezone: 'Asia/Tokyo', name: 'Aichi' },
      Akita: { code: 'AKI', timezone: 'Asia/Tokyo', name: 'Akita' },
      Aomori: { code: 'AOM', timezone: 'Asia/Tokyo', name: 'Aomori' },
      Chiba: { code: 'CHB', timezone: 'Asia/Tokyo', name: 'Chiba' },
      Ehime: { code: 'EHM', timezone: 'Asia/Tokyo', name: 'Ehime' },
      Fukui: { code: 'FKI', timezone: 'Asia/Tokyo', name: 'Fukui' },
      Fukushima: { code: 'FKS', timezone: 'Asia/Tokyo', name: 'Fukushima' },
      Gifu: { code: 'GIF', timezone: 'Asia/Tokyo', name: 'Gifu' },
      Gunma: { code: 'GUM', timezone: 'Asia/Tokyo', name: 'Gunma' },
      Hiroshima: { code: 'HGC', timezone: 'Asia/Tokyo', name: 'Hiroshima' },
      Hokkaido: { code: 'HKO', timezone: 'Asia/Tokyo', name: 'Hokkaido' },
      Hyogo: { code: 'HYG', timezone: 'Asia/Tokyo', name: 'Hyogo' },
      Ibaraki: { code: 'IBA', timezone: 'Asia/Tokyo', name: 'Ibaraki' },
      Ishikawa: { code: 'ISH', timezone: 'Asia/Tokyo', name: 'Ishikawa' },
      Iwate: { code: 'IWT', timezone: 'Asia/Tokyo', name: 'Iwate' },
      Kagawa: { code: 'KAG', timezone: 'Asia/Tokyo', name: 'Kagawa' },
      Kagoshima: { code: 'KGO', timezone: 'Asia/Tokyo', name: 'Kagoshima' },
      Kumamoto: { code: 'KUM', timezone: 'Asia/Tokyo', name: 'Kumamoto' },
      Kyoto: { code: 'KTO', timezone: 'Asia/Tokyo', name: 'Kyoto' },
      Mie: { code: 'MIE', timezone: 'Asia/Tokyo', name: 'Mie' },
      Miyagi: { code: 'MYG', timezone: 'Asia/Tokyo', name: 'Miyagi' },
      Miyazaki: { code: 'MYZ', timezone: 'Asia/Tokyo', name: 'Miyazaki' },
      Nagano: { code: 'NAG', timezone: 'Asia/Tokyo', name: 'Nagano' },
      Nagasaki: { code: 'NGA', timezone: 'Asia/Tokyo', name: 'Nagasaki' },
      Nara: { code: 'NAR', timezone: 'Asia/Tokyo', name: 'Nara' },
      Niigata: { code: 'NIG', timezone: 'Asia/Tokyo', name: 'Niigata' },
      Oita: { code: 'OIT', timezone: 'Asia/Tokyo', name: 'Oita' },
      Okayama: { code: 'OKA', timezone: 'Asia/Tokyo', name: 'Okayama' },
      Okinawa: { code: 'OKI', timezone: 'Asia/Tokyo', name: 'Okinawa' },
      Osaka: { code: 'OSA', timezone: 'Asia/Tokyo', name: 'Osaka' },
      Saga: { code: 'SAG', timezone: 'Asia/Tokyo', name: 'Saga' },
      Saitama: { code: 'SAI', timezone: 'Asia/Tokyo', name: 'Saitama' },
      Shiga: { code: 'SZO', timezone: 'Asia/Tokyo', name: 'Shiga' },
      Shimane: { code: 'SMN', timezone: 'Asia/Tokyo', name: 'Shimane' },
      Shizuoka: { code: 'SHZ', timezone: 'Asia/Tokyo', name: 'Shizuoka' },
      Tochigi: { code: 'TOC', timezone: 'Asia/Tokyo', name: 'Tochigi' },
      Tokyo: { code: 'TOK', timezone: 'Asia/Tokyo', name: 'Tokyo' },
      Tokushima: { code: 'TKO', timezone: 'Asia/Tokyo', name: 'Tokushima' },
      Tottori: { code: 'TTR', timezone: 'Asia/Tokyo', name: 'Tottori' },
      Toyama: { code: 'TOY', timezone: 'Asia/Tokyo', name: 'Toyama' },
      Wakayama: { code: 'WAK', timezone: 'Asia/Tokyo', name: 'Wakayama' },
      Yamagata: { code: 'YAM', timezone: 'Asia/Tokyo', name: 'Yamagata' },
      Yamaguchi: { code: 'YMG', timezone: 'Asia/Tokyo', name: 'Yamaguchi' },
      Yamanashi: { code: 'YMN', timezone: 'Asia/Tokyo', name: 'Yamanashi' },
    },
  },
  MY: {
    code: 'MY',
    name: 'Malaysia',
    timezone: 'Asia/Kuala_Lumpur',
    provinceLabel: 'State/Federal Territory',
    postalLabel: 'Postcode',
    provinces: {
      Johor: { code: 'JOH', timezone: 'Asia/Kuala_Lumpur', name: 'Johor' },
      Kedah: { code: 'KDH', timezone: 'Asia/Kuala_Lumpur', name: 'Kedah' },
      Kelantan: { code: 'KTN', timezone: 'Asia/Kuala_Lumpur', name: 'Kelantan' },
      Malacca: { code: 'MYS', timezone: 'Asia/Kuala_Lumpur', name: 'Malacca' },
      NegeriSembilan: { code: 'NSN', timezone: 'Asia/Kuala_Lumpur', name: 'Negeri Sembilan' },
      Pahang: { code: 'PHG', timezone: 'Asia/Kuala_Lumpur', name: 'Pahang' },
      Penang: { code: 'PNG', timezone: 'Asia/Kuala_Lumpur', name: 'Penang' },
      Perak: { code: 'PRK', timezone: 'Asia/Kuala_Lumpur', name: 'Perak' },
      Sabah: { code: 'SBH', timezone: 'Asia/Kuching', name: 'Sabah' },
      Sarawak: { code: 'SWK', timezone: 'Asia/Kuching', name: 'Sarawak' },
      Selangor: { code: 'SGR', timezone: 'Asia/Kuala_Lumpur', name: 'Selangor' },
      Terengganu: { code: 'TRG', timezone: 'Asia/Kuala_Lumpur', name: 'Terengganu' },
      KualaLumpur: { code: 'KUL', timezone: 'Asia/Kuala_Lumpur', name: 'Kuala Lumpur' },
      Labuan: { code: 'LBN', timezone: 'Asia/Kuala_Lumpur', name: 'Labuan' },
      Putrajaya: { code: 'PJY', timezone: 'Asia/Kuala_Lumpur', name: 'Putrajaya' },
    },
  },
  MX: {
    code: 'MX',
    name: 'Mexico',
    timezone: 'America/Mexico_City',
    provinceLabel: 'State',
    postalLabel: 'Postal Code',
    provinces: {
      Aguascalientes: { code: 'AG', timezone: 'America/Mexico_City', name: 'Aguascalientes' },
      'Baja California': { code: 'BC', timezone: 'America/Tijuana', name: 'Baja California' },
      'Baja California Sur': {
        code: 'BS',
        timezone: 'America/Mazatlan',
        name: 'Baja California Sur',
      },
      Campeche: { code: 'CM', timezone: 'America/Merida', name: 'Campeche' },
      Chiapas: { code: 'CS', timezone: 'America/Mexico_City', name: 'Chiapas' },
      Chihuahua: { code: 'CH', timezone: 'America/Chihuahua', name: 'Chihuahua' },
      Coahuila: { code: 'CO', timezone: 'America/Mexico_City', name: 'Coahuila' },
      Colima: { code: 'CL', timezone: 'America/Mexico_City', name: 'Colima' },
      'Ciudad de México': { code: 'DF', timezone: 'America/Mexico_City', name: 'Ciudad de México' },
      Durango: { code: 'DG', timezone: 'America/Monterrey', name: 'Durango' },
      Guanajuato: { code: 'GT', timezone: 'America/Mexico_City', name: 'Guanajuato' },
      Guerrero: { code: 'GR', timezone: 'America/Mexico_City', name: 'Guerrero' },
      Hidalgo: { code: 'HG', timezone: 'America/Mexico_City', name: 'Hidalgo' },
      Jalisco: { code: 'JA', timezone: 'America/Mexico_City', name: 'Jalisco' },
      'State of Mexico': { code: 'EM', timezone: 'America/Mexico_City', name: 'State of Mexico' },
      Michoacán: { code: 'MI', timezone: 'America/Mexico_City', name: 'Michoacán' },
      Morelos: { code: 'MO', timezone: 'America/Mexico_City', name: 'Morelos' },
      Nayarit: { code: 'NA', timezone: 'America/Mazatlan', name: 'Nayarit' },
      'Nuevo León': { code: 'NL', timezone: 'America/Monterrey', name: 'Nuevo León' },
      Oaxaca: { code: 'OA', timezone: 'America/Mexico_City', name: 'Oaxaca' },
      Puebla: { code: 'PU', timezone: 'America/Mexico_City', name: 'Puebla' },
      Querétaro: { code: 'QT', timezone: 'America/Mexico_City', name: 'Querétaro' },
      'Quintana Roo': { code: 'QR', timezone: 'America/Cancun', name: 'Quintana Roo' },
      'San Luis Potosí': { code: 'SL', timezone: 'America/Mexico_City', name: 'San Luis Potosí' },
      Sinaloa: { code: 'SI', timezone: 'America/Mazatlan', name: 'Sinaloa' },
      Sonora: { code: 'SO', timezone: 'America/Hermosillo', name: 'Sonora' },
      Tabasco: { code: 'TB', timezone: 'America/Merida', name: 'Tabasco' },
      Tamaulipas: { code: 'TM', timezone: 'America/Monterrey', name: 'Tamaulipas' },
      Tlaxcala: { code: 'TL', timezone: 'America/Mexico_City', name: 'Tlaxcala' },
      Veracruz: { code: 'VE', timezone: 'America/Mexico_City', name: 'Veracruz' },
      Yucatán: { code: 'YU', timezone: 'America/Merida', name: 'Yucatán' },
      Zacatecas: { code: 'ZA', timezone: 'America/Mexico_City', name: 'Zacatecas' },
    },
  },
  NP: {
    code: 'NP',
    name: 'Nepal',
    timezone: 'Asia/Kathmandu',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Koshi: { code: 'P1', timezone: 'Asia/Kathmandu', name: 'Koshi' },
      Madhesh: { code: 'P2', timezone: 'Asia/Kathmandu', name: 'Madhesh' },
      Bagmati: { code: 'P3', timezone: 'Asia/Kathmandu', name: 'Bagmati' },
      Gandaki: { code: 'P4', timezone: 'Asia/Kathmandu', name: 'Gandaki' },
      Lumbini: { code: 'P5', timezone: 'Asia/Kathmandu', name: 'Lumbini' },
      Karnali: { code: 'P6', timezone: 'Asia/Kathmandu', name: 'Karnali' },
      Sudurpashchim: { code: 'P7', timezone: 'Asia/Kathmandu', name: 'Sudurpashchim' },
    },
  },
  NL: {
    code: 'NL',
    name: 'Netherlands',
    timezone: 'Europe/Amsterdam',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      DR: { code: 'DR', timezone: 'Europe/Amsterdam', name: 'Drenthe' },
      FL: { code: 'FL', timezone: 'Europe/Amsterdam', name: 'Flevoland' },
      FR: { code: 'FR', timezone: 'Europe/Amsterdam', name: 'Friesland' },
      GE: { code: 'GE', timezone: 'Europe/Amsterdam', name: 'Gelderland' },
      GR: { code: 'GR', timezone: 'Europe/Amsterdam', name: 'Groningen' },
      LI: { code: 'LI', timezone: 'Europe/Amsterdam', name: 'Limburg' },
      NB: { code: 'NB', timezone: 'Europe/Amsterdam', name: 'North Brabant' },
      NH: { code: 'NH', timezone: 'Europe/Amsterdam', name: 'North Holland' },
      OV: { code: 'OV', timezone: 'Europe/Amsterdam', name: 'Overijssel' },
      UT: { code: 'UT', timezone: 'Europe/Amsterdam', name: 'Utrecht' },
      ZE: { code: 'ZE', timezone: 'Europe/Amsterdam', name: 'Zeeland' },
      ZH: { code: 'ZH', timezone: 'Europe/Amsterdam', name: 'South Holland' },
    },
  },
  NZ: {
    code: 'NZ',
    name: 'New Zealand',
    timezone: 'Pacific/Auckland',
    provinceLabel: 'Region',
    postalLabel: 'Postcode',
    provinces: {
      Auckland: { code: 'AUK', timezone: 'Pacific/Auckland', name: 'Auckland' },
      'Bay of Plenty': { code: 'BOP', timezone: 'Pacific/Auckland', name: 'Bay of Plenty' },
      Canterbury: { code: 'CAN', timezone: 'Pacific/Auckland', name: 'Canterbury' },
      Gisborne: { code: 'GIS', timezone: 'Pacific/Auckland', name: 'Gisborne' },
      "Hawke's Bay": { code: 'HKB', timezone: 'Pacific/Auckland', name: "Hawke's Bay" },
      Marlborough: { code: 'MBH', timezone: 'Pacific/Auckland', name: 'Marlborough' },
      'Manawatū-Whanganui': {
        code: 'MWT',
        timezone: 'Pacific/Auckland',
        name: 'Manawatū-Whanganui',
      },
      Nelson: { code: 'NSN', timezone: 'Pacific/Auckland', name: 'Nelson' },
      Northland: { code: 'NTK', timezone: 'Pacific/Auckland', name: 'Northland' },
      Otago: { code: 'OTA', timezone: 'Pacific/Auckland', name: 'Otago' },
      Southland: { code: 'STL', timezone: 'Pacific/Auckland', name: 'Southland' },
      Taranaki: { code: 'TKI', timezone: 'Pacific/Auckland', name: 'Taranaki' },
      Tasman: { code: 'TAS', timezone: 'Pacific/Auckland', name: 'Tasman' },
      Waikato: { code: 'WKO', timezone: 'Pacific/Auckland', name: 'Waikato' },
      Wellington: { code: 'WGN', timezone: 'Pacific/Auckland', name: 'Wellington' },
      'West Coast': { code: 'WTC', timezone: 'Pacific/Auckland', name: 'West Coast' },
      'Chatham Islands': { code: 'CHC', timezone: 'Pacific/Auckland', name: 'Chatham Islands' },
    },
  },
  NG: {
    code: 'NG',
    name: 'Nigeria',
    timezone: 'Africa/Lagos',
    provinceLabel: 'State',
    postalLabel: 'Postal Code',
    provinces: {
      AB: { code: 'AB', timezone: 'Africa/Lagos', name: 'Abia' },
      AD: { code: 'AD', timezone: 'Africa/Lagos', name: 'Adamawa' },
      AK: { code: 'AK', timezone: 'Africa/Lagos', name: 'Akwa Ibom' },
      AN: { code: 'AN', timezone: 'Africa/Lagos', name: 'Anambra' },
      BA: { code: 'BA', timezone: 'Africa/Lagos', name: 'Bauchi' },
      BY: { code: 'BY', timezone: 'Africa/Lagos', name: 'Bayelsa' },
      BE: { code: 'BE', timezone: 'Africa/Lagos', name: 'Benue' },
      BO: { code: 'BO', timezone: 'Africa/Lagos', name: 'Borno' },
      CR: { code: 'CR', timezone: 'Africa/Lagos', name: 'Cross River' },
      DE: { code: 'DE', timezone: 'Africa/Lagos', name: 'Delta' },
      EB: { code: 'EB', timezone: 'Africa/Lagos', name: 'Ebonyi' },
      ED: { code: 'ED', timezone: 'Africa/Lagos', name: 'Edo' },
      EK: { code: 'EK', timezone: 'Africa/Lagos', name: 'Ekiti' },
      EN: { code: 'EN', timezone: 'Africa/Lagos', name: 'Enugu' },
      GO: { code: 'GO', timezone: 'Africa/Lagos', name: 'Gombe' },
      IM: { code: 'IM', timezone: 'Africa/Lagos', name: 'Imo' },
      JI: { code: 'JI', timezone: 'Africa/Lagos', name: 'Jigawa' },
      KD: { code: 'KD', timezone: 'Africa/Lagos', name: 'Kaduna' },
      KN: { code: 'KN', timezone: 'Africa/Lagos', name: 'Kano' },
      KT: { code: 'KT', timezone: 'Africa/Lagos', name: 'Katsina' },
      KE: { code: 'KE', timezone: 'Africa/Lagos', name: 'Kebbi' },
      KO: { code: 'KO', timezone: 'Africa/Lagos', name: 'Kogi' },
      KW: { code: 'KW', timezone: 'Africa/Lagos', name: 'Kwara' },
      LA: { code: 'LA', timezone: 'Africa/Lagos', name: 'Lagos' },
      NA: { code: 'NA', timezone: 'Africa/Lagos', name: 'Nasarawa' },
      NI: { code: 'NI', timezone: 'Africa/Lagos', name: 'Niger' },
      OG: { code: 'OG', timezone: 'Africa/Lagos', name: 'Ogun' },
      ON: { code: 'ON', timezone: 'Africa/Lagos', name: 'Ondo' },
      OS: { code: 'OS', timezone: 'Africa/Lagos', name: 'Osun' },
      OY: { code: 'OY', timezone: 'Africa/Lagos', name: 'Oyo' },
      PL: { code: 'PL', timezone: 'Africa/Lagos', name: 'Plateau' },
      RI: { code: 'RI', timezone: 'Africa/Lagos', name: 'Rivers' },
      SO: { code: 'SO', timezone: 'Africa/Lagos', name: 'Sokoto' },
      TA: { code: 'TA', timezone: 'Africa/Lagos', name: 'Taraba' },
      YO: { code: 'YO', timezone: 'Africa/Lagos', name: 'Yobe' },
      ZA: { code: 'ZA', timezone: 'Africa/Lagos', name: 'Zamfara' },
      FC: { code: 'FC', timezone: 'Africa/Lagos', name: 'Federal Capital Territory' },
    },
  },
  PH: {
    code: 'PH',
    name: 'Philippines',
    timezone: 'Asia/Manila',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Abra: { code: 'ABR', timezone: 'Asia/Manila', name: 'Abra' },
      'Agusan del Norte': { code: 'AGN', timezone: 'Asia/Manila', name: 'Agusan del Norte' },
      'Agusan del Sur': { code: 'AGS', timezone: 'Asia/Manila', name: 'Agusan del Sur' },
      Aklan: { code: 'ALA', timezone: 'Asia/Manila', name: 'Aklan' },
      Albay: { code: 'ALB', timezone: 'Asia/Manila', name: 'Albay' },
      Antique: { code: 'ANT', timezone: 'Asia/Manila', name: 'Antique' },
      Apayao: { code: 'APY', timezone: 'Asia/Manila', name: 'Apayao' },
      Aurora: { code: 'AUR', timezone: 'Asia/Manila', name: 'Aurora' },
      Basilan: { code: 'BAS', timezone: 'Asia/Manila', name: 'Basilan' },
      Bataan: { code: 'BAN', timezone: 'Asia/Manila', name: 'Bataan' },
      Batanes: { code: 'BTN', timezone: 'Asia/Manila', name: 'Batanes' },
      Batangas: { code: 'BTS', timezone: 'Asia/Manila', name: 'Batangas' },
      Benguet: { code: 'BNG', timezone: 'Asia/Manila', name: 'Benguet' },
      Biliran: { code: 'BIL', timezone: 'Asia/Manila', name: 'Biliran' },
      Bohol: { code: 'BOH', timezone: 'Asia/Manila', name: 'Bohol' },
      Bukidnon: { code: 'BUK', timezone: 'Asia/Manila', name: 'Bukidnon' },
      Bulacan: { code: 'BUL', timezone: 'Asia/Manila', name: 'Bulacan' },
      Cagayan: { code: 'CAG', timezone: 'Asia/Manila', name: 'Cagayan' },
      'Camarines Norte': { code: 'CAN', timezone: 'Asia/Manila', name: 'Camarines Norte' },
      'Camarines Sur': { code: 'CAS', timezone: 'Asia/Manila', name: 'Camarines Sur' },
      Camiguin: { code: 'CAM', timezone: 'Asia/Manila', name: 'Camiguin' },
      Capiz: { code: 'CAP', timezone: 'Asia/Manila', name: 'Capiz' },
      Catanduanes: { code: 'CAT', timezone: 'Asia/Manila', name: 'Catanduanes' },
      Cavite: { code: 'CAV', timezone: 'Asia/Manila', name: 'Cavite' },
      Cebu: { code: 'CEU', timezone: 'Asia/Manila', name: 'Cebu' },
      'Davao de Oro': { code: 'COM', timezone: 'Asia/Manila', name: 'Davao de Oro' },
      'Davao del Norte': { code: 'DAV', timezone: 'Asia/Manila', name: 'Davao del Norte' },
      'Davao del Sur': { code: 'DAS', timezone: 'Asia/Manila', name: 'Davao del Sur' },
      'Davao Oriental': { code: 'DOR', timezone: 'Asia/Manila', name: 'Davao Oriental' },
      'Dinagat Islands': { code: 'DIN', timezone: 'Asia/Manila', name: 'Dinagat Islands' },
      'Eastern Samar': { code: 'EAS', timezone: 'Asia/Manila', name: 'Eastern Samar' },
      Guimaras: { code: 'GUI', timezone: 'Asia/Manila', name: 'Guimaras' },
      Ifugao: { code: 'IFU', timezone: 'Asia/Manila', name: 'Ifugao' },
      'Ilocos Norte': { code: 'ILN', timezone: 'Asia/Manila', name: 'Ilocos Norte' },
      'Ilocos Sur': { code: 'ILS', timezone: 'Asia/Manila', name: 'Ilocos Sur' },
      Iloilo: { code: 'ILO', timezone: 'Asia/Manila', name: 'Iloilo' },
      Isabela: { code: 'ISA', timezone: 'Asia/Manila', name: 'Isabela' },
      Kalinga: { code: 'KAL', timezone: 'Asia/Manila', name: 'Kalinga' },
      Laguna: { code: 'LAG', timezone: 'Asia/Manila', name: 'Laguna' },
      'Lanao del Norte': { code: 'LAN', timezone: 'Asia/Manila', name: 'Lanao del Norte' },
      'Lanao del Sur': { code: 'LAS', timezone: 'Asia/Manila', name: 'Lanao del Sur' },
      Leyte: { code: 'LEY', timezone: 'Asia/Manila', name: 'Leyte' },
      Maguindanao: { code: 'MAG', timezone: 'Asia/Manila', name: 'Maguindanao' },
      Marinduque: { code: 'MRN', timezone: 'Asia/Manila', name: 'Marinduque' },
      'Misamis Occidental': { code: 'MSC', timezone: 'Asia/Manila', name: 'Misamis Occidental' },
      'Misamis Oriental': { code: 'MSS', timezone: 'Asia/Manila', name: 'Misamis Oriental' },
      'Mountain Province': { code: 'MOU', timezone: 'Asia/Manila', name: 'Mountain Province' },
      'Nueva Ecija': { code: 'NEV', timezone: 'Asia/Manila', name: 'Nueva Ecija' },
      'Nueva Vizcaya': { code: 'NER', timezone: 'Asia/Manila', name: 'Nueva Vizcaya' },
      Palawan: { code: 'PAL', timezone: 'Asia/Manila', name: 'Palawan' },
      Pampanga: { code: 'PAM', timezone: 'Asia/Manila', name: 'Pampanga' },
      Pangasinan: { code: 'PBG', timezone: 'Asia/Manila', name: 'Pangasinan' },
      Quezon: { code: 'QZN', timezone: 'Asia/Manila', name: 'Quezon' },
      Quirino: { code: 'QUI', timezone: 'Asia/Manila', name: 'Quirino' },
      Rizal: { code: 'RIZ', timezone: 'Asia/Manila', name: 'Rizal' },
      Romblon: { code: 'ROM', timezone: 'Asia/Manila', name: 'Romblon' },
      Sarangani: { code: 'SAR', timezone: 'Asia/Manila', name: 'Sarangani' },
      Siquijor: { code: 'SIG', timezone: 'Asia/Manila', name: 'Siquijor' },
      Sorsogon: { code: 'SOR', timezone: 'Asia/Manila', name: 'Sorsogon' },
      'Southern Leyte': { code: 'SLE', timezone: 'Asia/Manila', name: 'Southern Leyte' },
      'South Cotabato': { code: 'SOU', timezone: 'Asia/Manila', name: 'South Cotabato' },
      'Sultan Kudarat': { code: 'SKU', timezone: 'Asia/Manila', name: 'Sultan Kudarat' },
      Sulu: { code: 'SLU', timezone: 'Asia/Manila', name: 'Sulu' },
      'Surigao del Norte': { code: 'SUR', timezone: 'Asia/Manila', name: 'Surigao del Norte' },
      'Surigao del Sur': { code: 'SUS', timezone: 'Asia/Manila', name: 'Surigao del Sur' },
      Tarlac: { code: 'TAR', timezone: 'Asia/Manila', name: 'Tarlac' },
      'Tawi-Tawi': { code: 'TAU', timezone: 'Asia/Manila', name: 'Tawi-Tawi' },
      Zambales: { code: 'ZMB', timezone: 'Asia/Manila', name: 'Zambales' },
      'Zamboanga del Norte': { code: 'ZAN', timezone: 'Asia/Manila', name: 'Zamboanga del Norte' },
      'Zamboanga del Sur': { code: 'ZAS', timezone: 'Asia/Manila', name: 'Zamboanga del Sur' },
      'Zamboanga Sibugay': { code: 'ZSI', timezone: 'Asia/Manila', name: 'Zamboanga Sibugay' },
    },
  },
  PK: {
    code: 'PK',
    name: 'Pakistan',
    timezone: 'Asia/Karachi',
    provinceLabel: 'Province/Territory',
    postalLabel: 'Postal Code',
    provinces: {
      Balochistan: { code: 'BALO', timezone: 'Asia/Karachi', name: 'Balochistan' },
      'Khyber Pakhtunkhwa': { code: 'KP', timezone: 'Asia/Karachi', name: 'Khyber Pakhtunkhwa' },
      Punjab: { code: 'PUN', timezone: 'Asia/Karachi', name: 'Punjab' },
      Sindh: { code: 'SINDH', timezone: 'Asia/Karachi', name: 'Sindh' },
      'Gilgit-Baltistan': { code: 'GB', timezone: 'Asia/Karachi', name: 'Gilgit-Baltistan' },
      'Islamabad Capital Territory': {
        code: 'ICT',
        timezone: 'Asia/Karachi',
        name: 'Islamabad Capital Territory',
      },
      'Azad Jammu and Kashmir': {
        code: 'AJK',
        timezone: 'Asia/Karachi',
        name: 'Azad Jammu and Kashmir',
      },
    },
  },
  QA: {
    code: 'QA',
    name: 'Qatar',
    timezone: 'Asia/Qatar',
    provinceLabel: 'Municipality',
    postalLabel: 'Postal Code',
    provinces: {
      'Ad Dawhah (Doha)': { code: 'DA', timezone: 'Asia/Qatar', name: 'Ad Dawhah (Doha)' },
      'Al Khawr': { code: 'KH', timezone: 'Asia/Qatar', name: 'Al Khawr' },
      'Ash Shamal': { code: 'MS', timezone: 'Asia/Qatar', name: 'Ash Shamal' },
      'Ar Rayyan': { code: 'RA', timezone: 'Asia/Qatar', name: 'Ar Rayyan' },
      'Al Wakrah': { code: 'WA', timezone: 'Asia/Qatar', name: 'Al Wakrah' },
      'Umm Salal': { code: 'US', timezone: 'Asia/Qatar', name: 'Umm Salal' },
      'Az̧ Z̧a‘āyin': { code: 'ZU', timezone: 'Asia/Qatar', name: 'Az̧ Z̧a‘āyin' },
    },
  },
  RO: {
    code: 'RO',
    name: 'Romania',
    timezone: 'Europe/Bucharest',
    provinceLabel: 'County',
    postalLabel: 'Postal Code',
    provinces: {
      AB: { code: 'AB', timezone: 'Europe/Bucharest', name: 'Alba' },
      AR: { code: 'AR', timezone: 'Europe/Bucharest', name: 'Arad' },
      AG: { code: 'AG', timezone: 'Europe/Bucharest', name: 'Argeș' },
      BC: { code: 'BC', timezone: 'Europe/Bucharest', name: 'Bacău' },
      BH: { code: 'BH', timezone: 'Europe/Bucharest', name: 'Bihor' },
      BN: { code: 'BN', timezone: 'Europe/Bucharest', name: 'Bistrița-Năsăud' },
      BR: { code: 'BR', timezone: 'Europe/Bucharest', name: 'Brăila' },
      BT: { code: 'BT', timezone: 'Europe/Bucharest', name: 'Botoșani' },
      BV: { code: 'BV', timezone: 'Europe/Bucharest', name: 'Brașov' },
      BZ: { code: 'BZ', timezone: 'Europe/Bucharest', name: 'Buzău' },
      CS: { code: 'CS', timezone: 'Europe/Bucharest', name: 'Caraș-Severin' },
      CL: { code: 'CL', timezone: 'Europe/Bucharest', name: 'Călărași' },
      CJ: { code: 'CJ', timezone: 'Europe/Bucharest', name: 'Cluj' },
      CT: { code: 'CT', timezone: 'Europe/Bucharest', name: 'Constanța' },
      CV: { code: 'CV', timezone: 'Europe/Bucharest', name: 'Covasna' },
      DB: { code: 'DB', timezone: 'Europe/Bucharest', name: 'Dâmbovița' },
      DJ: { code: 'DJ', timezone: 'Europe/Bucharest', name: 'Dolj' },
      GL: { code: 'GL', timezone: 'Europe/Bucharest', name: 'Galați' },
      GR: { code: 'GR', timezone: 'Europe/Bucharest', name: 'Giurgiu' },
      GJ: { code: 'GJ', timezone: 'Europe/Bucharest', name: 'Gorj' },
      HR: { code: 'HR', timezone: 'Europe/Bucharest', name: 'Harghita' },
      HD: { code: 'HD', timezone: 'Europe/Bucharest', name: 'Hunedoara' },
      IL: { code: 'IL', timezone: 'Europe/Bucharest', name: 'Ialomița' },
      IS: { code: 'IS', timezone: 'Europe/Bucharest', name: 'Iași' },
      IF: { code: 'IF', timezone: 'Europe/Bucharest', name: 'Ilfov' },
      MM: { code: 'MM', timezone: 'Europe/Bucharest', name: 'Maramureș' },
      MH: { code: 'MH', timezone: 'Europe/Bucharest', name: 'Mehedinți' },
      MS: { code: 'MS', timezone: 'Europe/Bucharest', name: 'Mureș' },
      NT: { code: 'NT', timezone: 'Europe/Bucharest', name: 'Neamț' },
      OT: { code: 'OT', timezone: 'Europe/Bucharest', name: 'Olt' },
      PH: { code: 'PH', timezone: 'Europe/Bucharest', name: 'Prahova' },
      SM: { code: 'SM', timezone: 'Europe/Bucharest', name: 'Satu Mare' },
      SJ: { code: 'SJ', timezone: 'Europe/Bucharest', name: 'Sălaj' },
      SB: { code: 'SB', timezone: 'Europe/Bucharest', name: 'Sibiu' },
      SV: { code: 'SV', timezone: 'Europe/Bucharest', name: 'Suceava' },
      TR: { code: 'TR', timezone: 'Europe/Bucharest', name: 'Teleorman' },
      TM: { code: 'TM', timezone: 'Europe/Bucharest', name: 'Timiș' },
      TL: { code: 'TL', timezone: 'Europe/Bucharest', name: 'Tulcea' },
      VS: { code: 'VS', timezone: 'Europe/Bucharest', name: 'Vaslui' },
      VL: { code: 'VL', timezone: 'Europe/Bucharest', name: 'Vâlcea' },
      VN: { code: 'VN', timezone: 'Europe/Bucharest', name: 'Vrancea' },
      B: { code: 'B', timezone: 'Europe/Bucharest', name: 'Bucharest' },
    },
  },
  SA: {
    code: 'SA',
    name: 'Saudi Arabia',
    timezone: 'Asia/Riyadh',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Riyadh: { code: '01', timezone: 'Asia/Riyadh', name: 'Riyadh' },
      Makkah: { code: '02', timezone: 'Asia/Riyadh', name: 'Makkah' },
      Madinah: { code: '03', timezone: 'Asia/Riyadh', name: 'Madinah' },
      'Eastern Province': { code: '04', timezone: 'Asia/Riyadh', name: 'Eastern Province' },
      Asir: { code: '05', timezone: 'Asia/Riyadh', name: 'Asir' },
      Tabuk: { code: '06', timezone: 'Asia/Riyadh', name: 'Tabuk' },
      Hail: { code: '07', timezone: 'Asia/Riyadh', name: 'Hail' },
      'Northern Borders': { code: '08', timezone: 'Asia/Riyadh', name: 'Northern Borders' },
      Jizan: { code: '09', timezone: 'Asia/Riyadh', name: 'Jizan' },
      Najran: { code: '10', timezone: 'Asia/Riyadh', name: 'Najran' },
      'Al Bahah': { code: '11', timezone: 'Asia/Riyadh', name: 'Al Bahah' },
      'Al Jawf': { code: '12', timezone: 'Asia/Riyadh', name: 'Al Jawf' },
      'Al-Qassim': { code: '14', timezone: 'Asia/Riyadh', name: 'Al-Qassim' },
    },
  },
  TZ: {
    code: 'TZ',
    name: 'Tanzania',
    timezone: 'Africa/Dar_es_Salaam',
    provinceLabel: 'Region',
    postalLabel: 'Postal Code',
    provinces: {
      Arusha: { code: 'AR', timezone: 'Africa/Dar_es_Salaam', name: 'Arusha' },
      'Dar es Salaam': { code: 'DS', timezone: 'Africa/Dar_es_Salaam', name: 'Dar es Salaam' },
      Dodoma: { code: 'DO', timezone: 'Africa/Dar_es_Salaam', name: 'Dodoma' },
      Geita: { code: 'GE', timezone: 'Africa/Dar_es_Salaam', name: 'Geita' },
      Iringa: { code: 'IR', timezone: 'Africa/Dar_es_Salaam', name: 'Iringa' },
      Kagera: { code: 'KA', timezone: 'Africa/Dar_es_Salaam', name: 'Kagera' },
      Katavi: { code: 'KT', timezone: 'Africa/Dar_es_Salaam', name: 'Katavi' },
      Kigoma: { code: 'KI', timezone: 'Africa/Dar_es_Salaam', name: 'Kigoma' },
      Kilimanjaro: { code: 'KL', timezone: 'Africa/Dar_es_Salaam', name: 'Kilimanjaro' },
      Lindi: { code: 'LI', timezone: 'Africa/Dar_es_Salaam', name: 'Lindi' },
      Manyara: { code: 'MY', timezone: 'Africa/Dar_es_Salaam', name: 'Manyara' },
      Mara: { code: 'MA', timezone: 'Africa/Dar_es_Salaam', name: 'Mara' },
      Mbeya: { code: 'MB', timezone: 'Africa/Dar_es_Salaam', name: 'Mbeya' },
      Morogoro: { code: 'MO', timezone: 'Africa/Dar_es_Salaam', name: 'Morogoro' },
      Mtwara: { code: 'MT', timezone: 'Africa/Dar_es_Salaam', name: 'Mtwara' },
      Mwanza: { code: 'MW', timezone: 'Africa/Dar_es_Salaam', name: 'Mwanza' },
      Njombe: { code: 'NJ', timezone: 'Africa/Dar_es_Salaam', name: 'Njombe' },
      Pemba: { code: 'PE', timezone: 'Africa/Dar_es_Salaam', name: 'Pemba North' },
      Pwani: { code: 'PW', timezone: 'Africa/Dar_es_Salaam', name: 'Pwani' },
      Rukwa: { code: 'RU', timezone: 'Africa/Dar_es_Salaam', name: 'Rukwa' },
      Ruvuma: { code: 'RV', timezone: 'Africa/Dar_es_Salaam', name: 'Ruvuma' },
      Shinyanga: { code: 'SH', timezone: 'Africa/Dar_es_Salaam', name: 'Shinyanga' },
      Simiyu: { code: 'SI', timezone: 'Africa/Dar_es_Salaam', name: 'Simiyu' },
      Singida: { code: 'SD', timezone: 'Africa/Dar_es_Salaam', name: 'Singida' },
      Songwe: { code: 'SO', timezone: 'Africa/Dar_es_Salaam', name: 'Songwe' },
      Tabora: { code: 'TB', timezone: 'Africa/Dar_es_Salaam', name: 'Tabora' },
      Tanga: { code: 'TN', timezone: 'Africa/Dar_es_Salaam', name: 'Tanga' },
      Zanzibar: { code: 'ZN', timezone: 'Africa/Dar_es_Salaam', name: 'Zanzibar' },
    },
  },
  GB: {
    code: 'GB',
    name: 'United Kingdom',
    timezone: 'Europe/London',
    provinceLabel: 'Country/Region',
    postalLabel: 'Postcode',
    provinces: {
      // England Regions
      'North East England': {
        code: 'ENG_NE',
        timezone: 'Europe/London',
        name: 'North East England',
      },
      'North West England': {
        code: 'ENG_NW',
        timezone: 'Europe/London',
        name: 'North West England',
      },
      'Yorkshire and the Humber': {
        code: 'ENG_YH',
        timezone: 'Europe/London',
        name: 'Yorkshire and the Humber',
      },
      'East Midlands': { code: 'ENG_EM', timezone: 'Europe/London', name: 'East Midlands' },
      'West Midlands': { code: 'ENG_WM', timezone: 'Europe/London', name: 'West Midlands' },
      'East of England': { code: 'ENG_EA', timezone: 'Europe/London', name: 'East of England' },
      London: { code: 'ENG_LD', timezone: 'Europe/London', name: 'London' },
      'South East England': {
        code: 'ENG_SE',
        timezone: 'Europe/London',
        name: 'South East England',
      },
      'South West England': {
        code: 'ENG_SW',
        timezone: 'Europe/London',
        name: 'South West England',
      },

      // Scotland Regions
      Highlands: { code: 'SCT_HI', timezone: 'Europe/London', name: 'Highlands' },
      Grampian: { code: 'SCT_GR', timezone: 'Europe/London', name: 'Grampian' },
      Tayside: { code: 'SCT_TY', timezone: 'Europe/London', name: 'Tayside' },
      Fife: { code: 'SCT_FI', timezone: 'Europe/London', name: 'Fife' },
      Lothian: { code: 'SCT_LO', timezone: 'Europe/London', name: 'Lothian' },
      Strathclyde: { code: 'SCT_ST', timezone: 'Europe/London', name: 'Strathclyde' },
      'Dumfries and Galloway': {
        code: 'SCT_DG',
        timezone: 'Europe/London',
        name: 'Dumfries and Galloway',
      },
      Borders: { code: 'SCT_BR', timezone: 'Europe/London', name: 'Borders' },
      Islands: { code: 'SCT_IS', timezone: 'Europe/London', name: 'Islands' },

      // Wales Regions
      'North Wales': { code: 'WLS_NC', timezone: 'Europe/London', name: 'North Wales' },
      'Central Wales': { code: 'WLS_CE', timezone: 'Europe/London', name: 'Central Wales' },
      'South East Wales': { code: 'WLS_SE', timezone: 'Europe/London', name: 'South East Wales' },
      'South West Wales': { code: 'WLS_SW', timezone: 'Europe/London', name: 'South West Wales' },

      // Northern Ireland Regions
      'Antrim and Newtownabbey': {
        code: 'NIR_AN',
        timezone: 'Europe/London',
        name: 'Antrim and Newtownabbey',
      },
      'Ards and North Down': {
        code: 'NIR_AD',
        timezone: 'Europe/London',
        name: 'Ards and North Down',
      },
      'Armagh City, Banbridge and Craigavon': {
        code: 'NIR_AM',
        timezone: 'Europe/London',
        name: 'Armagh City, Banbridge and Craigavon',
      },
      Belfast: { code: 'NIR_BF', timezone: 'Europe/London', name: 'Belfast' },
      'Causeway Coast and Glens': {
        code: 'NIR_CC',
        timezone: 'Europe/London',
        name: 'Causeway Coast and Glens',
      },
      'Derry City and Strabane': {
        code: 'NIR_DE',
        timezone: 'Europe/London',
        name: 'Derry City and Strabane',
      },
      'Fermanagh and Omagh': {
        code: 'NIR_FO',
        timezone: 'Europe/London',
        name: 'Fermanagh and Omagh',
      },
      'Lisburn and Castlereagh': {
        code: 'NIR_LM',
        timezone: 'Europe/London',
        name: 'Lisburn and Castlereagh',
      },
      'Mid and East Antrim': {
        code: 'NIR_ME',
        timezone: 'Europe/London',
        name: 'Mid and East Antrim',
      },
      'Mid Ulster': { code: 'NIR_MU', timezone: 'Europe/London', name: 'Mid Ulster' },
      'Newry, Mourne and Down': {
        code: 'NIR_NM',
        timezone: 'Europe/London',
        name: 'Newry, Mourne and Down',
      },
    },
  },
  ZA: {
    code: 'ZA',
    name: 'South Africa',
    timezone: 'Africa/Johannesburg',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      EC: { code: 'EC', timezone: 'Africa/Johannesburg', name: 'Eastern Cape' },
      FS: { code: 'FS', timezone: 'Africa/Johannesburg', name: 'Free State' },
      GP: { code: 'GP', timezone: 'Africa/Johannesburg', name: 'Gauteng' },
      KZN: { code: 'KZN', timezone: 'Africa/Johannesburg', name: 'KwaZulu-Natal' },
      LP: { code: 'LP', timezone: 'Africa/Johannesburg', name: 'Limpopo' },
      MP: { code: 'MP', timezone: 'Africa/Johannesburg', name: 'Mpumalanga' },
      NC: { code: 'NC', timezone: 'Africa/Johannesburg', name: 'Northern Cape' },
      NW: { code: 'NW', timezone: 'Africa/Johannesburg', name: 'North West' },
      WC: { code: 'WC', timezone: 'Africa/Johannesburg', name: 'Western Cape' },
    },
  },
  VE: {
    code: 'VE',
    name: 'Venezuela',
    timezone: 'America/Caracas',
    provinceLabel: 'State',
    postalLabel: 'Postal Code',
    provinces: {
      Amazonas: { code: 'Z', timezone: 'America/Caracas', name: 'Amazonas' },
      Anzoátegui: { code: 'A', timezone: 'America/Caracas', name: 'Anzoátegui' },
      Apure: { code: 'B', timezone: 'America/Caracas', name: 'Apure' },
      Aragua: { code: 'C', timezone: 'America/Caracas', name: 'Aragua' },
      Barinas: { code: 'D', timezone: 'America/Caracas', name: 'Barinas' },
      Bolívar: { code: 'E', timezone: 'America/Caracas', name: 'Bolívar' },
      Carabobo: { code: 'F', timezone: 'America/Caracas', name: 'Carabobo' },
      Cojedes: { code: 'G', timezone: 'America/Caracas', name: 'Cojedes' },
      'Delta Amacuro': { code: 'H', timezone: 'America/Caracas', name: 'Delta Amacuro' },
      Falcón: { code: 'I', timezone: 'America/Caracas', name: 'Falcón' },
      Guárico: { code: 'J', timezone: 'America/Caracas', name: 'Guárico' },
      Lara: { code: 'K', timezone: 'America/Caracas', name: 'Lara' },
      Mérida: { code: 'L', timezone: 'America/Caracas', name: 'Mérida' },
      Miranda: { code: 'M', timezone: 'America/Caracas', name: 'Miranda' },
      Monagas: { code: 'N', timezone: 'America/Caracas', name: 'Monagas' },
      'Nueva Esparta': { code: 'O', timezone: 'America/Caracas', name: 'Nueva Esparta' },
      Portuguesa: { code: 'P', timezone: 'America/Caracas', name: 'Portuguesa' },
      Sucre: { code: 'R', timezone: 'America/Caracas', name: 'Sucre' },
      Táchira: { code: 'S', timezone: 'America/Caracas', name: 'Táchira' },
      Trujillo: { code: 'T', timezone: 'America/Caracas', name: 'Trujillo' },
      'La Guaira (Vargas)': { code: 'U', timezone: 'America/Caracas', name: 'La Guaira (Vargas)' },
      Yaracuy: { code: 'V', timezone: 'America/Caracas', name: 'Yaracuy' },
      Zulia: { code: 'W', timezone: 'America/Caracas', name: 'Zulia' },
      'Capital District (Distrito Capital)': {
        code: 'X',
        timezone: 'America/Caracas',
        name: 'Capital District (Distrito Capital)',
      },
    },
  },
  VN: {
    code: 'VN',
    name: 'Vietnam',
    timezone: 'Asia/Ho_Chi_Minh',
    provinceLabel: 'Province',
    postalLabel: 'Postal Code',
    provinces: {
      Hanoi: { code: 'HNO', timezone: 'Asia/Ho_Chi_Minh', name: 'Hanoi' },
      'Ho Chi Minh City': { code: 'SGN', timezone: 'Asia/Ho_Chi_Minh', name: 'Ho Chi Minh City' },
      'Hai Phong': { code: 'HPH', timezone: 'Asia/Ho_Chi_Minh', name: 'Hai Phong' },
      'Da Nang': { code: 'DNG', timezone: 'Asia/Ho_Chi_Minh', name: 'Da Nang' },
      'Can Tho': { code: 'CTO', timezone: 'Asia/Ho_Chi_Minh', name: 'Can Tho' },
      'An Giang': { code: 'AGG', timezone: 'Asia/Ho_Chi_Minh', name: 'An Giang' },
      'Binh Dinh': { code: 'BDH', timezone: 'Asia/Ho_Chi_Minh', name: 'Binh Dinh' },
      'Binh Duong': { code: 'BDG', timezone: 'Asia/Ho_Chi_Minh', name: 'Binh Duong' },
      'Binh Phuoc': { code: 'BPC', timezone: 'Asia/Ho_Chi_Minh', name: 'Binh Phuoc' },
      'Binh Thuan': { code: 'BTN', timezone: 'Asia/Ho_Chi_Minh', name: 'Binh Thuan' },
      'Ca Mau': { code: 'CMU', timezone: 'Asia/Ho_Chi_Minh', name: 'Ca Mau' },
      'Dak Lak': { code: 'DLK', timezone: 'Asia/Ho_Chi_Minh', name: 'Dak Lak' },
      'Dak Nong': { code: 'DKNG', timezone: 'Asia/Ho_Chi_Minh', name: 'Dak Nong' },
      'Dien Bien': { code: 'DBN', timezone: 'Asia/Ho_Chi_Minh', name: 'Dien Bien' },
      'Dong Nai': { code: 'DNA', timezone: 'Asia/Ho_Chi_Minh', name: 'Dong Nai' },
      'Dong Thap': { code: 'DTP', timezone: 'Asia/Ho_Chi_Minh', name: 'Dong Thap' },
      'Gia Lai': { code: 'GLA', timezone: 'Asia/Ho_Chi_Minh', name: 'Gia Lai' },
      'Ha Giang': { code: 'HGG', timezone: 'Asia/Ho_Chi_Minh', name: 'Ha Giang' },
      'Ha Nam': { code: 'HNM', timezone: 'Asia/Ho_Chi_Minh', name: 'Ha Nam' },
      'Ha Tinh': { code: 'HTH', timezone: 'Asia/Ho_Chi_Minh', name: 'Ha Tinh' },
      'Hai Duong': { code: 'HDG', timezone: 'Asia/Ho_Chi_Minh', name: 'Hai Duong' },
      'Hoa Binh': { code: 'HBH', timezone: 'Asia/Ho_Chi_Minh', name: 'Hoa Binh' },
      'Hung Yen': { code: 'HGN', timezone: 'Asia/Ho_Chi_Minh', name: 'Hung Yen' },
      'Khanh Hoa': { code: 'KHA', timezone: 'Asia/Ho_Chi_Minh', name: 'Khanh Hoa' },
      'Kien Giang': { code: 'KGG', timezone: 'Asia/Ho_Chi_Minh', name: 'Kien Giang' },
      'Kon Tum': { code: 'KTM', timezone: 'Asia/Ho_Chi_Minh', name: 'Kon Tum' },
      'Lai Chau': { code: 'LCU', timezone: 'Asia/Ho_Chi_Minh', name: 'Lai Chau' },
      'Lam Dong': { code: 'LDG', timezone: 'Asia/Ho_Chi_Minh', name: 'Lam Dong' },
      'Lang Son': { code: 'LSN', timezone: 'Asia/Ho_Chi_Minh', name: 'Lang Son' },
      'Lao Cai': { code: 'LCA', timezone: 'Asia/Ho_Chi_Minh', name: 'Lao Cai' },
      'Long An': { code: 'LAN', timezone: 'Asia/Ho_Chi_Minh', name: 'Long An' },
      'Nam Dinh': { code: 'NDH', timezone: 'Asia/Ho_Chi_Minh', name: 'Nam Dinh' },
      'Nghe An': { code: 'NAN', timezone: 'Asia/Ho_Chi_Minh', name: 'Nghe An' },
      'Ninh Binh': { code: 'NBH', timezone: 'Asia/Ho_Chi_Minh', name: 'Ninh Binh' },
      'Ninh Thuan': { code: 'NTN', timezone: 'Asia/Ho_Chi_Minh', name: 'Ninh Thuan' },
      'Phu Tho': { code: 'PTO', timezone: 'Asia/Ho_Chi_Minh', name: 'Phu Tho' },
      'Phu Yen': { code: 'PYN', timezone: 'Asia/Ho_Chi_Minh', name: 'Phu Yen' },
      'Quang Binh': { code: 'QBH', timezone: 'Asia/Ho_Chi_Minh', name: 'Quang Binh' },
      'Quang Nam': { code: 'QNM', timezone: 'Asia/Ho_Chi_Minh', name: 'Quang Nam' },
      'Quang Ninh': { code: 'QNI', timezone: 'Asia/Ho_Chi_Minh', name: 'Quang Ninh' },
      'Quang Ngai': { code: 'QNG', timezone: 'Asia/Ho_Chi_Minh', name: 'Quang Ngai' },
      'Quang Tri': { code: 'QTR', timezone: 'Asia/Ho_Chi_Minh', name: 'Quang Tri' },
      'Soc Trang': { code: 'STG', timezone: 'Asia/Ho_Chi_Minh', name: 'Soc Trang' },
      'Son La': { code: 'SLA', timezone: 'Asia/Ho_Chi_Minh', name: 'Son La' },
      'Tay Ninh': { code: 'TNH', timezone: 'Asia/Ho_Chi_Minh', name: 'Tay Ninh' },
      'Thai Binh': { code: 'TBH', timezone: 'Asia/Ho_Chi_Minh', name: 'Thai Binh' },
      'Thai Nguyen': { code: 'TNN', timezone: 'Asia/Ho_Chi_Minh', name: 'Thai Nguyen' },
      'Thanh Hoa': { code: 'THA', timezone: 'Asia/Ho_Chi_Minh', name: 'Thanh Hoa' },
      'Thua Thien-Hue': { code: 'THH', timezone: 'Asia/Ho_Chi_Minh', name: 'Thua Thien-Hue' },
      'Tien Giang': { code: 'TGG', timezone: 'Asia/Ho_Chi_Minh', name: 'Tien Giang' },
      'Tra Vinh': { code: 'TVH', timezone: 'Asia/Ho_Chi_Minh', name: 'Tra Vinh' },
      'Tuyen Quang': { code: 'TQG', timezone: 'Asia/Ho_Chi_Minh', name: 'Tuyen Quang' },
      'Vinh Long': { code: 'VLG', timezone: 'Asia/Ho_Chi_Minh', name: 'Vinh Long' },
      'Vinh Phuc': { code: 'VPC', timezone: 'Asia/Ho_Chi_Minh', name: 'Vinh Phuc' },
      'Yen Bai': { code: 'YBA', timezone: 'Asia/Ho_Chi_Minh', name: 'Yen Bai' },
      'Ba Ria-Vung Tau': { code: 'BRV', timezone: 'Asia/Ho_Chi_Minh', name: 'Ba Ria-Vung Tau' },
      'Bac Ninh': { code: 'BNH', timezone: 'Asia/Ho_Chi_Minh', name: 'Bac Ninh' },
      'Bac Kan': { code: 'BKN', timezone: 'Asia/Ho_Chi_Minh', name: 'Bac Kan' },
      'Bac Lieu': { code: 'BLU', timezone: 'Asia/Ho_Chi_Minh', name: 'Bac Lieu' },
      'Bac Giang': { code: 'BGG', timezone: 'Asia/Ho_Chi_Minh', name: 'Bac Giang' },
      'Ben Tre': { code: 'BTE', timezone: 'Asia/Ho_Chi_Minh', name: 'Ben Tre' },
      'Hau Giang': { code: 'HGI', timezone: 'Asia/Ho_Chi_Minh', name: 'Hau Giang' },
    },
  },
};

export const validCountry = (country: unknown): country is Country =>
  typeof country === 'string' && Object.keys(geography).includes(country);

export const getTimezone = (country: Country, province = '') => {
  type FlattenedTimezones = UnionToIntersection<(typeof TimezonesByCountry)[typeof country]>;

  const timezones = TimezonesByCountry[country];

  const prov = Object.keys(timezones).includes(province)
    ? (province as keyof FlattenedTimezones)
    : undefined;

  return prov ? (get(timezones as FlattenedTimezones, [prov]) ?? null) : null;
};

export const provinceLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'State' : 'Province';

export const postalCodeLabel = (country?: string) =>
  country && validCountry(country) && country === Country.US ? 'Zip Code' : 'Postal Code';

const ProvinceNames = Object.fromEntries(
  Object.entries({
    ...geography.PA.provinces,
    ...geography.US.provinces,
    ...geography.CA.provinces,
  }).map(([k, { name }]) => [k, name])
);

export const provinceName = (province?: string) =>
  province ? ProvinceNames[province.toUpperCase()] : (province ?? province);

export const DEFAULT_GST = 0.05;

export const getCondoLabels = (province: keyof typeof Provinces | string | undefined) =>
  province === Provinces.BC
    ? {
        condo: 'strata',
        unitFactor: 'unit entitlement',
      }
    : {
        condo: 'condo',
        unitFactor: 'unit factor',
      };

export const placeToAddress = (place?: Place, country: Country = Country.CA) => {
  const timezone = getTimezone(country, place?.province);

  return {
    ...(place?.streetName && { street: place.streetName }),
    ...(place?.city && { city: place.city }),
    ...(place?.postalCode && { postal: place.postalCode }),
    ...(place?.lat && { lat: place.lat }),
    ...(place?.lng && { lng: place.lng }),
    ...(place?.province && { province: place.province }),
    ...(timezone ? { timezone } : {}),
    ...(country && { country }),
  };
};
