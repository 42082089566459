import {
  SupplierAutocompleteField,
  SupplierAutocompleteFieldProps,
} from 'components/Fields/SupplierAutocompleteField';
import { useController } from 'react-hook-form';
export type SupplierAutocompleteControllerProps = {
  name: string;
} & SupplierAutocompleteFieldProps;

export default function SupplierAutocompleteController({
  name,
  ...controllerProps
}: SupplierAutocompleteControllerProps) {
  const { field, fieldState } = useController({ name });

  return controllerProps.selectionMode === 'multiple' ? (
    <SupplierAutocompleteField
      variant="outlined"
      error={fieldState.error}
      {...{ ...controllerProps, ...field }}
      selectionMode="multiple"
      onChange={(newValue) => {
        field.onChange(newValue?.map(({ id }) => id) ?? '', { shouldValidate: true });
        controllerProps.onChange?.(newValue);
      }}
    />
  ) : (
    <SupplierAutocompleteField
      variant="outlined"
      error={fieldState.error}
      {...{ ...controllerProps, ...field }}
      onChange={(newValue) => {
        field.onChange(newValue?.id ?? '', { shouldValidate: true });
        controllerProps.onChange?.(newValue);
      }}
    />
  );
}
