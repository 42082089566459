import { QueryHookOptions, QueryResult } from '@apollo/client';
import { useEffect, useState } from 'react';
import { ensureArray, ifDifferent } from '../system';
import { useNetworkStatusMeta } from './useNetworkStatusMeta';

type PaginateAllQueryOptions<
  TData extends Record<string, unknown>,
  TVariables extends { nextToken?: string },
  TItems extends Array<unknown> | undefined,
> = QueryHookOptions<TData, TVariables> & {
  getNextToken: (data?: TData) => string | undefined;
  getItems?: (data: TData) => TItems;
};

export const usePaginateAllQuery = <
  TData extends Record<string, unknown>,
  TVariables extends { nextToken?: string },
  TItems extends Array<unknown> | undefined = Array<unknown> | undefined,
>(
  useListData: (options: QueryHookOptions<TData, TVariables>) => QueryResult<TData, TVariables>,
  { getNextToken, getItems, ...options }: PaginateAllQueryOptions<TData, TVariables, TItems>
) => {
  const [localOptions, setLocalOptions] = useState(options);
  useEffect(() => setLocalOptions(ifDifferent(options)), [options]);

  const { fetchMore, ...meta } = useListData({
    notifyOnNetworkStatusChange: true,
    ...localOptions,
  });

  const nextToken = getNextToken(meta.data);
  const items = ensureArray(meta.data ? getItems?.(meta.data) : (undefined as unknown as TItems));
  const { loading, working, done } = useNetworkStatusMeta(meta);

  useEffect(() => {
    nextToken && fetchMore({ variables: { ...localOptions.variables, nextToken } });
  }, [fetchMore, nextToken, localOptions]);

  return {
    ...meta,
    done: options.skip || done,
    loading,
    working,
    items,
  };
};
