import { useApolloClient } from '@apollo/client';
import { FirstPage } from '@mui/icons-material';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListSubheader,
  Switch,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { logout, useAuth, useAuthDispatch, useTheme } from 'context';
import { useBooksActivated } from 'hooks/useBooksActivated';
import { useProperties } from 'hooks/useProperties';
import { groupBy } from 'lodash';
import { Drawer, FormControlLabel, Logo, Nav } from './AuthDrawer.styles';
import NavListItem from './NavListItem';
import { authNav } from './authNav';

type AuthDrawerProps = {
  collapse: boolean;
  mobileOpen: boolean;
  handleCollapse: VoidFunction;
  handleExpand: VoidFunction;
  handleMobileToggle: VoidFunction;
};

export default function AuthDrawer({
  collapse,
  handleExpand,
  mobileOpen,
  handleMobileToggle,
  handleCollapse,
}: AuthDrawerProps) {
  const { darkMode, toggleDarkMode } = useTheme();
  const smUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const { includesAnyRole } = useAuth();
  const { activated: isBooksActive, isCountryAllowed } = useBooksActivated();
  const { properties } = useProperties();

  const dispatch = useAuthDispatch();
  const client = useApolloClient();

  const logoutHandler = async () => {
    await logout(dispatch);
    client.clearStore();
  };

  const hasResidentialUnits = properties.some((p) => p.rentalUnits ?? p.ownerUnits);
  const hasCommercialUnits = properties.some((p) => p.commercialUnits);

  const items = authNav({
    logoutHandler,
    isBooksActive,
    hasResidentialUnits,
    hasCommercialUnits,
    isCountryAllowed,
  });
  const visibleItems = items.filter(
    ({ restrictions }) => !restrictions || includesAnyRole(...restrictions)
  );
  const groups = Object.values(groupBy(visibleItems, 'section'));

  return (
    <Nav aria-label="navigation">
      <Drawer
        anchor="left"
        variant={smUp ? 'permanent' : 'temporary'}
        open={mobileOpen}
        collapse={collapse}
        onClose={smUp ? undefined : handleMobileToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Grid container direction="column" justifyContent="space-between" sx={{ height: '100%' }}>
          <Grid item>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs="auto">
                <Logo
                  color="primary"
                  short={collapse}
                  onClick={collapse ? handleExpand : undefined}
                />
              </Grid>
              {!collapse && (
                <Grid item xs="auto">
                  <IconButton
                    onClick={handleCollapse}
                    sx={{ mt: -1.5, mr: 1, display: { xs: 'none', sm: 'block' } }}
                    size="large"
                  >
                    <FirstPage />
                  </IconButton>
                </Grid>
              )}
            </Grid>
            <List dense>
              {groups.map((group, index) => (
                <Box key={index}>
                  {group[0].section && (
                    <>
                      {collapse ? (
                        <Divider variant="middle" sx={{ m: 2 }} />
                      ) : (
                        <ListSubheader sx={{ mt: 5 }}>
                          <Typography variant="overline">{group[0].section}</Typography>
                        </ListSubheader>
                      )}
                    </>
                  )}

                  {group.map((props) => (
                    <NavListItem
                      key={props.label}
                      {...props}
                      sx={{ color: 'text.primary' }}
                      short={collapse}
                      disabled={props.requiresProperties && properties.length === 0}
                    />
                  ))}
                </Box>
              ))}
            </List>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Switch checked={darkMode} onChange={toggleDarkMode} color="primary" />}
              label={collapse ? '' : 'Dark mode'}
            />
          </Grid>
        </Grid>
      </Drawer>
    </Nav>
  );
}
