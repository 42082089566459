import { compact } from '@propra-system/registry';
import { OwnersFilterInput, usePageAccountOwnersQuery } from 'api';
import { useAccount } from 'pages/account/hooks/useAccount';
import { useMemo } from 'react';
import { ensureArray } from 'system';
import { useMeta } from './useMeta';

type UseServerOwnersProps = {
  first?: number;
  after?: string;
  filter?: OwnersFilterInput;
  skip?: boolean;
};

export const useServerOwners = ({ first, after, filter, skip }: UseServerOwnersProps) => {
  const { accountName, accountId } = useAccount();
  const { data, ...meta } = usePageAccountOwnersQuery({
    variables: { first, after, filter },
    skip,
  });

  const { loading } = useMeta(meta);

  const owners = useMemo(
    () => ensureArray(data?.account?.pageOwners?.edges).map(({ node }) => node),
    [data]
  );

  const ownerOptions = useMemo(
    () =>
      compact([
        filter?.booksOwner && accountId && accountName
          ? {
              id: accountId,
              text: accountName,
              subText: 'Administrative',
            }
          : null,
        ...owners.map(({ id, name: ownerName, propertyIds, unitIds, email }) => ({
          id,
          text: ownerName,
          ...(filter?.booksOwner
            ? { subText: propertyIds?.[0] ? 'Property Owner' : unitIds?.[0] ? 'Unit Owner' : '' }
            : { subText: email }),
        })),
      ]),
    [owners, accountName, accountId, filter?.booksOwner]
  );

  return {
    owners,
    ownerOptions,
    loading,
  };
};
