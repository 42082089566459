import { TaxCategory, useGetProvinceTaxDetailsLazyQuery } from 'api';
import { useCallback } from 'react';
import { ensureArray } from 'system';

export const useGetProvinceTaxDetails = () => {
  const [getTaxDetailsByProvince] = useGetProvinceTaxDetailsLazyQuery();

  const getTaxDetails = useCallback(
    async ({
      province,
      postal,
      country,
      category,
      taxExempted,
    }: {
      province?: string;
      postal?: string;
      country?: string;
      category?: TaxCategory;
      taxExempted?: boolean;
    }) => {
      const { data } = await getTaxDetailsByProvince({
        variables: { input: { province, postal, country, taxExempted, category } },
      });

      return ensureArray(data?.getProvinceTaxDetails?.taxDetails);
    },
    [getTaxDetailsByProvince]
  );

  return { getTaxDetails };
};
