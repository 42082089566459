import { ServerSupplierFieldsFragment } from 'api';
import { AutocompleteField, AutocompleteMultiField } from 'components';
import { useDebouncedValue } from 'hooks/useDebounce';
import { useServerSuppliers } from 'hooks/useServerSuppliers';
import { useState } from 'react';
import { matches, Option } from 'system';

type MultipleProps = {
  selectionMode: 'multiple';
  value?: string[];
  defaultValue?: string[];
  onChange?: (suppliers?: ServerSupplierFieldsFragment[]) => void;
  filter?: Partial<Parameters<typeof useServerSuppliers>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteMultiField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

type SingleProps = {
  selectionMode?: 'single';
  value?: string | Option<string>;
  defaultValue?: string | Option<string>;
  onChange?: (supplier?: ServerSupplierFieldsFragment) => void;
  filter?: Partial<Parameters<typeof useServerSuppliers>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

const isMultiple = matches({ selectionMode: 'multiple' as const });

export type SupplierAutocompleteFieldProps = MultipleProps | SingleProps;

export const SupplierAutocompleteField = ({
  filter,
  ...fieldProps
}: SupplierAutocompleteFieldProps) => {
  const [searchText, setSearchText] = useState('');
  const [debouncedSearch, debouncing] = useDebouncedValue(searchText, { delay: 250 });
  const { suppliers, supplierOptions, loading } = useServerSuppliers({
    filter: { ...filter, search: debouncedSearch },
  });

  return isMultiple(fieldProps) ? (
    <AutocompleteMultiField
      variant="filled"
      options={supplierOptions}
      loading={loading || debouncing}
      label={fieldProps.label}
      {...fieldProps}
      onInputChange={(_, value) => {
        setSearchText(value);
      }}
      onSelected={(newValue) => {
        fieldProps.onChange?.(suppliers.filter(({ id }) => newValue.includes(id)));
      }}
    />
  ) : (
    <AutocompleteField
      variant="filled"
      options={supplierOptions}
      hideIcons={true}
      loading={loading || debouncing}
      label={fieldProps.label}
      {...fieldProps}
      onInputChange={(_, value) => {
        setSearchText(value);
      }}
      onChange={(_, newValue) => {
        const newId = typeof newValue === 'string' ? newValue : newValue?.id;
        fieldProps.onChange?.(suppliers.find(({ id }) => id === newId));
      }}
    />
  );
};
