import { FetchResult } from '@apollo/client';
import { useCallback } from 'react';
import { useNotification } from './useNotification';

export const usePayloadNotification = () => {
  const { sendNotification } = useNotification();

  const payloadNotification = useCallback(
    <T extends { success?: boolean; error?: string }>({
      payload,
      success,
      error,
    }: {
      payload?: T;
      success: string;
      error: string;
    }) => {
      if (payload) {
        if (payload.success) {
          sendNotification(success, 'success');
        } else {
          sendNotification(payload.error ?? error, 'error');
        }
      }
    },
    [sendNotification]
  );

  const promiseNotification = useCallback(
    <TData, TResult = boolean>({
      promise,
      getPayload,
      successMessage,
      defaultErrorMessage,
      getResult,
    }: {
      promise: Promise<FetchResult<TData>>;
      getPayload: (
        data: FetchResult<TData>['data']
      ) => { success?: boolean; error?: string } | undefined;
      successMessage: string;
      defaultErrorMessage: string;
      getResult?: (data: FetchResult<TData>['data']) => TResult;
    }): Promise<false | TResult> =>
      promise
        .then(({ data }) => {
          const { success, error } = getPayload(data) ?? {};
          if (success) {
            successMessage && sendNotification(successMessage, 'success');
            return getResult ? getResult(data) : (true as TResult);
          } else {
            sendNotification(error ?? defaultErrorMessage, 'error');
            return false;
          }
        })
        .catch((e) => {
          console.error(e);
          sendNotification('message' in e ? e.message : defaultErrorMessage, 'error');
          return false;
        }),
    [sendNotification]
  );

  return { payloadNotification, promiseNotification };
};
