import {
  OwnerAutocompleteField,
  OwnerAutocompleteFieldProps,
} from 'components/Fields/OwnerAutocompleteField';
import { useController } from 'react-hook-form';

export type OwnerAutocompleteControllerProps = {
  name: string;
} & OwnerAutocompleteFieldProps;

export default function OwnerAutocompleteController({
  name,
  ...fieldProps
}: OwnerAutocompleteControllerProps) {
  const { field, fieldState } = useController({ name });

  return fieldProps.selectionMode === 'multiple' ? (
    <OwnerAutocompleteField
      variant="outlined"
      error={fieldState.error}
      {...{ ...fieldProps, ...field }}
      selectionMode="multiple"
      onChange={(newValue) => {
        field.onChange(
          newValue?.map((o) => o.id),
          { shouldValidate: true }
        );
        fieldProps.onChange?.(newValue);
      }}
    />
  ) : (
    <OwnerAutocompleteField
      variant="outlined"
      error={fieldState.error}
      {...{ ...fieldProps, ...field }}
      onChange={(newValue) => {
        field.onChange(newValue?.id ?? '', { shouldValidate: true });
        fieldProps.onChange?.(newValue);
      }}
    />
  );
}
