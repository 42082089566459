import { useGetBooksActivationQuery } from 'api';
import { useAccountingEvents } from 'pages/accounting/hooks';

export const useBooksActivated = () => {
  const { data, loading } = useGetBooksActivationQuery();

  const activated = Boolean(data?.account?.books?.activatedZ);
  const autopay = Boolean(data?.account?.books?.autopay?.activated);
  const autopayVersion = data?.account?.books?.autopay?.version;
  const autopayActivationStatus = data?.account?.books?.autopay?.activationStatus;
  const enablePayments = data?.account?.books?.autopay?.enablePayments;
  const enableCollection = data?.account?.books?.autopay?.enableCollection;
  const accountingAllowedCountries = ['CA'];
  const isCountryAllowed = accountingAllowedCountries.includes(
    data?.account?.address?.country ?? 'CA'
  );

  useAccountingEvents(activated);

  const autopayV2 = autopay && autopayVersion === '2';

  const autopayStarted = ['STARTED', 'APPLICATION_PENDING'].includes(autopayActivationStatus ?? '');
  const autopayReviewPending =
    autopayStarted ||
    ['UNDERWRITING', 'CREDIT_RISK_REVIEW', 'COMPLIANCE_REVIEW'].includes(
      autopayActivationStatus ?? ''
    );

  return {
    activated,
    autopay,
    autopayV2,
    autopayStarted,
    autopayReviewPending,
    autopayVersion,
    autopayActivationStatus,
    enablePayments,
    enableCollection,
    isCountryAllowed,
    loading,
  };
};
