import { GetPropertyUnitsQuery, useGetPropertyUnitsQuery } from 'api';
import { useGetProvinceTaxDetails } from 'hooks/useGetProvinceTaxDetails';
import { useMeta } from 'hooks/useMeta';
import { useAccount } from 'pages/account/hooks/useAccount';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_GST, ensureArray } from 'system';
type Units = Required<Required<GetPropertyUnitsQuery>['property']>['units'];

export const useProperty = ({ propertyId = '' }: { propertyId?: string }) => {
  const [units, setUnits] = useState<Units>([]);
  const [name, setName] = useState('');
  const { getTaxDetails } = useGetProvinceTaxDetails();
  const [taxDetails, setTaxDetails] = useState({
    gstHstTaxRate: DEFAULT_GST,
    pstTaxRate: 0,
    gstName: 'GST',
  });

  const { data, ...meta } = useGetPropertyUnitsQuery({
    variables: { id: propertyId },
    skip: !propertyId,
  });
  useMeta(meta);
  const { account } = useAccount();

  const propertyProvince = useMemo(
    () => (data?.property?.address?.province ?? account?.address?.province)?.toUpperCase() ?? '',
    [data?.property?.address?.province, account?.address?.province]
  );

  useEffect(() => {
    setName(data?.property?.name ?? '');
    setUnits(ensureArray(data?.property?.units));
  }, [data]);

  const fetchTaxDetails = useCallback(
    async (province?: string) => {
      if (!province) return;

      const taxes = await getTaxDetails({ province });
      const gst = taxes.find((tax) => tax.gst);
      const pst = taxes.find((tax) => tax.pst);

      setTaxDetails({
        gstHstTaxRate: gst?.rate ?? DEFAULT_GST,
        pstTaxRate: pst?.rate ?? 0,
        gstName: gst?.label ?? 'GST',
      });
    },
    [getTaxDetails]
  );

  useEffect(() => {
    fetchTaxDetails(propertyProvince);
  }, [propertyProvince, fetchTaxDetails]);

  return {
    units,
    name,
    gstHstTaxRate: taxDetails.gstHstTaxRate,
    gstName: taxDetails.gstName,
    pstTaxRate: taxDetails.pstTaxRate,
    propertyProvince,
    propertyAutopayCollectionsEnabled: data?.property?.autopayCollectionsEnabled !== false,
  };
};
