import equal from 'fast-deep-equal/es6';
import { usePrevious } from 'hooks/usePrevious';
import {
  castArray,
  debounce,
  filter,
  find,
  intersection,
  isEmpty,
  map,
  orderBy,
  sortBy,
} from 'lodash';
import plur from 'plur';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { emptyArray, ensureArray } from 'system';
import {
  EntityOption,
  OwnerEntityOption,
  ownersToOptions,
  useAccountingContext,
} from '../../context';
import { SelectionMode } from '../../types';
import { useTransformCollection } from '../useTransformCollection';
import { ownerSelectionFor } from './helpers';

type UsePropertyOwnerSelectionProps = {
  value: { propertyId?: string | string[]; ownerId?: string | string[]; unitId?: string };
  showDisabledOwners?: boolean;
  allowUnitSelection?: boolean;
  allowMultipleOwners?: boolean;
  allowMultipleProperties?: boolean;
  filterOwners?: (owners: OwnerEntityOption[]) => OwnerEntityOption[];
  filterProperties?: (properties: EntityOption[]) => EntityOption[];
  onSelected?: (arg: {
    propertyId: string | string[];
    ownerId: string | string[];
    unitId?: string;
  }) => void;
} & (
  | {
      defaultMode?: SelectionMode.OwnerFirst | SelectionMode.PropertyFirst;
    }
  | {
      defaultMode: SelectionMode.OnlyOwner;
      propertyIds: string[];
    }
  | {
      defaultMode: SelectionMode.OnlyProperty;
      ownerIds: string[];
    }
);

export const usePropertyOwnerSelection = ({
  showDisabledOwners,
  allowUnitSelection,
  allowMultipleOwners,
  allowMultipleProperties,
  filterOwners,
  filterProperties,
  value,
  onSelected,
  ...props
}: UsePropertyOwnerSelectionProps) => {
  const { defaultMode } = props;
  const lockedPropertyIds =
    defaultMode === SelectionMode.OnlyOwner ? props.propertyIds : emptyArray;
  const lockedOwnerIds = defaultMode === SelectionMode.OnlyProperty ? props.ownerIds : emptyArray;

  // TODO: need a way to get books owners, a property's ownerId and the relevant properties + units
  const {
    entityIds,
    getPropertyOwnerId,
    unitsOwnedInProperty,
    propertyName,
    loading,
    booksOwners,
  } = useAccountingContext();
  const loadingChanged = loading !== usePrevious(loading);

  const defaultOwnerSelection = allowMultipleOwners ? ensureArray<string[]>() : '';
  const defaultPropertySelection = allowMultipleProperties ? ensureArray<string[]>() : '';

  const ownerId = useMemo(
    () =>
      defaultMode === SelectionMode.OnlyProperty
        ? lockedOwnerIds.join()
        : (value.ownerId ?? defaultOwnerSelection),
    [defaultMode, defaultOwnerSelection, lockedOwnerIds, value.ownerId]
  );
  const ownerIdChanged = !equal(ownerId, usePrevious(ownerId));

  const propertyId = useMemo(
    () =>
      defaultMode === SelectionMode.OnlyOwner
        ? lockedPropertyIds.join()
        : (value.propertyId ?? defaultPropertySelection),
    [defaultMode, defaultPropertySelection, lockedPropertyIds, value.propertyId]
  );
  const propertyIdChanged = !equal(propertyId, usePrevious(propertyId));

  const onSelectedRef = useRef((key: keyof typeof value, val: string | string[]) => {
    onSelected?.({ ...value, ownerId, propertyId, [key]: val });
  });
  useEffect(() => {
    onSelectedRef.current = debounce(
      (key: keyof typeof value, val: string | string[]) =>
        onSelected?.({ ...value, ownerId, propertyId, [key]: val }),
      250
    );
  }, [onSelected, ownerId, propertyId, value]);

  const unitOptions = entityIds.unit;
  const filterPropertiesRef = useRef(filterProperties);
  useEffect(() => {
    filterPropertiesRef.current = filterProperties;
  }, [filterProperties]);

  const propertyOptions = useMemo(
    () =>
      filterPropertiesRef.current
        ? filterPropertiesRef.current(entityIds.property)
        : entityIds.property,
    [entityIds.property]
  );

  const filterOwnersRef = useRef(filterOwners);
  useEffect(() => {
    filterOwnersRef.current = filterOwners;
  }, [filterOwners]);

  const ownerOptions = useMemo(() => {
    const options = ownersToOptions(booksOwners, { includeDisabled: showDisabledOwners }).concat(
      entityIds.account
    );

    return filterOwnersRef.current ? filterOwnersRef.current(options) : options;
  }, [booksOwners, entityIds.account, showDisabledOwners]);

  const [selectionMode, setSelectionMode] = useState<typeof defaultMode>(
    defaultMode ?? SelectionMode.PropertyFirst
  );
  const selectionModeChanged = selectionMode !== usePrevious(selectionMode);

  const toggleMode = () => {
    setSelectionMode((mode) =>
      mode === SelectionMode.OnlyOwner || mode === SelectionMode.OnlyProperty
        ? mode
        : mode === SelectionMode.OwnerFirst
          ? SelectionMode.PropertyFirst
          : SelectionMode.OwnerFirst
    );
  };

  const { transformedCollection: filteredOwnerOptions } = useTransformCollection({
    collection: ownerOptions,
    skip:
      selectionMode === SelectionMode.OwnerFirst || selectionMode === SelectionMode.OnlyProperty,
    transform: useCallback(() => {
      const propertyIds = [
        ...(Array.isArray(propertyId) ? propertyId : (propertyId ?? '').split(',')),
        ...lockedPropertyIds,
      ];

      const mappedOwners = (showDisabledOwners ? booksOwners : filter(booksOwners, 'enabled')).map(
        ({ id, name: text, allPropertyIds }) => {
          const propertyOwnerId = getPropertyOwnerId(propertyIds[0] ?? 'invalid');
          const [ownerPropertyId] = intersection(propertyIds, allPropertyIds);
          const property = find(entityIds.property, { id: ownerPropertyId });

          const unitsOwned = unitsOwnedInProperty(ownerPropertyId, id).length;

          return {
            id,
            text,
            ownerPropertyId,
            propertyText: property?.text,
            subText:
              id === propertyOwnerId
                ? 'Property owner'
                : `${unitsOwned} ${plur('unit', unitsOwned)}`,
          };
        }
      );

      return orderBy(
        ownerId === 'all' ? mappedOwners : mappedOwners.filter((opt) => opt.ownerPropertyId),
        ['propertyText', ({ subText }) => subText === 'Property owner'],
        ['asc', 'desc']
      ).concat(
        entityIds.account.map((account) => ({
          ...account,
          propertyText: 'Administrative',
          subText: '',
          ownerPropertyId: '',
        }))
      );
    }, [
      booksOwners,
      entityIds.account,
      entityIds.property,
      getPropertyOwnerId,
      ownerId,
      propertyId,
      showDisabledOwners,
      unitsOwnedInProperty,
      lockedPropertyIds,
    ]),
  });
  const previousFilteredOwners = usePrevious(filteredOwnerOptions);
  const ownerSelection = useMemo(
    () =>
      ownerSelectionFor({
        defaultOwnerSelection,
        accountOption: entityIds.account[0],
        ownerOptions: previousFilteredOwners ?? [],
      }),
    [defaultOwnerSelection, entityIds.account, previousFilteredOwners]
  );
  const updateOwnerSelection = useCallback(
    (newPropertyId: string | string[] = '', newOwnerId: string | string[] = '') => {
      const nextOwnerValue = ownerSelection({
        newOwnerId,
        newPropertyId,
        newOwnerOptions: filteredOwnerOptions.filter((o) =>
          entityIds.account.every((a) => a.id !== o.id)
        ),
        propertyOwnerOptions: map(filteredOwnerOptions, 'id'),
      });

      const selectedOwnerIds = intersection(map(filteredOwnerOptions, 'id'), castArray(newOwnerId));

      if (nextOwnerValue) {
        onSelectedRef.current('ownerId', nextOwnerValue);
      } else if (isEmpty(selectedOwnerIds)) {
        onSelectedRef.current('ownerId', defaultOwnerSelection);
      }
    },
    [ownerSelection, filteredOwnerOptions, entityIds.account, defaultOwnerSelection]
  );

  const { transformedCollection: filteredPropertyOptions } = useTransformCollection({
    collection: propertyOptions,
    skip:
      selectionMode === SelectionMode.PropertyFirst || selectionMode === SelectionMode.OnlyOwner,
    transform: useCallback(() => {
      const ownerIds = new Set([
        ...(Array.isArray(ownerId) ? ownerId : (ownerId ?? '').split(',')),
        ...lockedOwnerIds,
      ]);
      const ownedPropertyIds = new Set(
        filter(booksOwners, ({ id }) => ownerIds.has(id)).flatMap((owner) =>
          ensureArray(owner?.allPropertyIds)
        )
      );

      const entities = entityIds.account.some(({ id }) => ownerIds.has(id))
        ? entityIds.account.concat(entityIds.property)
        : entityIds.property.filter(({ id }) => ownedPropertyIds.has(id));

      return sortBy(
        entities.map((propertyOption) => {
          const ownerText = find(
            booksOwners,
            ({ id, allPropertyIds }) =>
              ownerIds.has(id) && allPropertyIds.includes(propertyOption.id)
          )?.name;

          return { ownerText, ...propertyOption };
        }),
        'ownerText'
      );
    }, [booksOwners, entityIds.account, entityIds.property, ownerId, lockedOwnerIds]),
  });
  const updatePropertySelection = useCallback(
    (newOwnerId?: string | string[], newPropertyId: string | string[] = '') => {
      if (!newOwnerId) {
        onSelectedRef.current('propertyId', defaultPropertySelection);
      } else if (newOwnerId && newPropertyId === 'all' && filteredPropertyOptions.length > 0) {
        onSelectedRef.current(
          'propertyId',
          filteredPropertyOptions.map(({ id }) => id)
        );
      } else if (
        newOwnerId &&
        newPropertyId !== 'all' &&
        (!newPropertyId || newPropertyId.length === 0) &&
        filteredPropertyOptions.length > 0
      ) {
        const selectedOption = Array.isArray(newPropertyId)
          ? [filteredPropertyOptions[0].id]
          : filteredPropertyOptions[0].id;

        onSelectedRef.current('propertyId', selectedOption);
      }
    },
    [defaultPropertySelection, filteredPropertyOptions]
  );

  const { transformedCollection: filteredUnitOptions } = useTransformCollection({
    collection: allowUnitSelection ? unitOptions : [],
    skip: !allowUnitSelection || isEmpty(propertyId),
    transform: useCallback(
      (opts: typeof unitOptions) =>
        opts
          .filter(
            ({ propertyId: unitPropertyId }) =>
              unitPropertyId &&
              (Array.isArray(propertyId) ? propertyId : (propertyId ?? '').split(',')).includes(
                unitPropertyId
              )
          )
          .map((opt) => ({ ...opt, text: opt.unitText, propertyText: propertyName(opt) })),
      [propertyId, propertyName]
    ),
  });

  const updateAllOptions = useCallback(
    (newOwnerId: string, newPropertyId: string): void => {
      updateOwnerSelection(newPropertyId, newOwnerId);
      updatePropertySelection(newOwnerId, newPropertyId);
    },
    [updateOwnerSelection, updatePropertySelection]
  );

  useEffect(() => {
    propertyId === 'all' && ownerId === 'all'
      ? updateAllOptions(ownerId, propertyId)
      : selectionMode === SelectionMode.PropertyFirst && (propertyIdChanged || selectionModeChanged)
        ? updateOwnerSelection(propertyId, ownerId)
        : selectionMode === SelectionMode.OwnerFirst && (ownerIdChanged || selectionModeChanged)
          ? updatePropertySelection(ownerId, propertyId)
          : undefined;
  }, [
    selectionMode,
    propertyId,
    ownerId,
    updateAllOptions,
    propertyIdChanged,
    updateOwnerSelection,
    ownerIdChanged,
    updatePropertySelection,
    selectionModeChanged,
  ]);

  useEffect(() => {
    if (loadingChanged && !loading) {
      propertyId === 'all' && ownerId === 'all'
        ? updateAllOptions(ownerId, propertyId)
        : selectionMode === SelectionMode.PropertyFirst
          ? updateOwnerSelection(propertyId, ownerId)
          : updatePropertySelection(ownerId, propertyId);
    }
  }, [
    loading,
    loadingChanged,
    ownerId,
    propertyId,
    selectionMode,
    updateAllOptions,
    updateOwnerSelection,
    updatePropertySelection,
  ]);

  return {
    loading,
    selectionMode,
    ownerOptions,
    filteredOwnerOptions,
    propertyOptions,
    filteredPropertyOptions,
    filteredUnitOptions,
    toggleMode,
    ...value,
    ownerId,
    propertyId,
  };
};
