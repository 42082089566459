import { useGetAccountQuery } from 'api';
import { useTranslation } from 'react-i18next';
import { useMeta } from './useMeta';

export const useTranslate = () => {
  const { data, ...meta } = useGetAccountQuery();
  const { loading } = useMeta(meta);
  const { t } = useTranslation('translation', {
    lng: `en-${data?.account?.address?.country ?? 'CA'}`,
  });

  const showGstHst = (data?.account?.address?.country ?? 'CA') === 'CA';
  const accountName = data?.account?.name;

  return { t, loading, showGstHst, accountName };
};
