import { PersistentState, usePersistentState } from 'hooks/usePersistentState';
import { uniq } from 'lodash';
import { ReactNode, SetStateAction, createContext, useCallback, useContext } from 'react';
import { ifDifferent } from 'system';

export type PropertyOwnerContextType = {
  ownerId: string | string[] | undefined;
  propertyId: string | string[] | undefined;
  setPropertyOwner: (
    setter: SetStateAction<Pick<PropertyOwnerContextType, 'ownerId' | 'propertyId'>>
  ) => void;
};

const initialState: PropertyOwnerContextType = {
  ownerId: undefined,
  propertyId: undefined,
  setPropertyOwner: () => undefined,
};

const PropertyOwnerContext = createContext<PropertyOwnerContextType>(initialState);

export const usePropertyOwnerContext = () => {
  const context = useContext(PropertyOwnerContext);
  if (!context) {
    throw new Error('usePropertyOwnerContext must be used within an PropertyOwnerProvider');
  }

  return context;
};

export const PropertyOwnerProvider = ({
  children,
  ownerId: initialOwnerId = [],
  propertyId: initialPropertyId = [],
}: {
  children: ReactNode;
  propertyId?: string | string[];
  ownerId?: string | string[];
}) => {
  const [{ ownerId, propertyId }, _setPropertyOwner] = usePersistentState<{
    ownerId: string | string[] | undefined;
    propertyId: string | string[] | undefined;
  }>(PersistentState.propertyOwner, {
    ownerId: initialOwnerId,
    propertyId: initialPropertyId,
  });

  const setPropertyOwner = useCallback(
    (setter: SetStateAction<Pick<PropertyOwnerContextType, 'ownerId' | 'propertyId'>>) => {
      const newState = typeof setter === 'function' ? setter({ ownerId, propertyId }) : setter;

      _setPropertyOwner(
        ifDifferent({
          ownerId: Array.isArray(newState.ownerId) ? uniq(newState.ownerId) : newState.ownerId,
          propertyId: Array.isArray(newState.propertyId)
            ? uniq(newState.propertyId)
            : newState.propertyId,
        })
      );
    },
    [_setPropertyOwner, ownerId, propertyId]
  );

  return (
    <PropertyOwnerContext.Provider
      value={{
        ownerId,
        propertyId,
        setPropertyOwner,
      }}
    >
      {children}
    </PropertyOwnerContext.Provider>
  );
};
