import { SuppliersFilterInput, usePageAccountSuppliersQuery } from 'api';
import { useMemo } from 'react';
import { ensureArray } from 'system';
import { useMeta } from './useMeta';

type UseServerSuppliersProps = {
  first?: number;
  after?: string;
  filter?: SuppliersFilterInput;
  skip?: boolean;
};

export const useServerSuppliers = ({ first, after, filter, skip }: UseServerSuppliersProps) => {
  const { data, ...meta } = usePageAccountSuppliersQuery({
    variables: { first, after, filter },
    skip,
  });

  const { loading } = useMeta(meta);

  const suppliers = useMemo(
    () => ensureArray(data?.account?.pageSuppliers?.edges).map(({ node }) => node),
    [data]
  );

  const supplierOptions = useMemo(
    () =>
      suppliers.map(({ id, name, email }) => ({
        id,
        text: name,
        subText: email,
      })),
    [suppliers]
  );

  return {
    suppliers,
    supplierOptions,
    loading,
  };
};
