import { useEffect, useRef } from 'react';

export const useInterval = (callback: VoidFunction, delay?: number) => {
  const savedCallback = useRef(callback);
  const intervalRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (!delay && delay !== 0) {
      clearInterval(intervalRef.current);
      return;
    }

    const id = setInterval(tick, delay);
    intervalRef.current = id;
    return () => clearInterval(id);
  }, [delay]);
};
