import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enCA from './system/translations/en-CA.json';
import enUS from './system/translations/en-US.json';

i18n.use(initReactI18next).init({
  lng: 'en-CA',
  fallbackLng: 'en-CA',
  resources: {
    'en-US': enUS,
    'en-CA': enCA,
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
