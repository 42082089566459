import { InMemoryCache } from '@apollo/client';
import { managerApprovalDeclinedEvent } from '@propra-manager/shapes/approval/managerApprovalDeclinedEvent';
import { managerAutopayActivationChangedEvent } from '@propra-manager/shapes/autopay/managerAutopayActivationChangedEvent';
import { managerPayeeAutopayActivatedEvent } from '@propra-manager/shapes/autopay/managerPayeeAutopayActivatedEvent';
import { managerPayeeAutopayCancelledEvent } from '@propra-manager/shapes/autopay/managerPayeeAutopayCancelledEvent';
import { managerPayeeAutopayFailedEvent } from '@propra-manager/shapes/autopay/managerPayeeAutopayFailedEvent';
import { managerAutopayPaymentsPrototypeCompletedEvent } from '@propra-manager/shapes/autopay/payments/prototypeCompletedEvent';
import { managerBooksBatchProgressUpdatedEvent } from '@propra-manager/shapes/books/batch/managerBooksBatchProgressUpdatedEvent';
import { managerResidencyResidentPaymentDetailsUpdatedEvent } from '@propra-manager/shapes/residency/managerResidencyResidentPaymentDetailsUpdatedEvent';
import { managerUnitPaymentDetailsUpdatedEvent } from '@propra-manager/shapes/unit/managerUnitPaymentDetailsUpdatedEvent';
import { shapeHandler } from '@propra-system/shape-handler';
import { ApprovalType } from 'api';
import { capitalize, constant } from 'lodash';
import { graphqlNull, invalidate } from 'system';

export const cacheShapeHandlers = (cache: InMemoryCache, accountId: string) => [
  shapeHandler(managerAutopayPaymentsPrototypeCompletedEvent, () => {
    cache.modify({
      id: cache.identify({ accountId, __typename: 'Books' }),
      fields: {
        pageClearables: invalidate,
      },
    });

    return Promise.resolve();
  }),
  shapeHandler(managerApprovalDeclinedEvent, ({ detail: { type } }) => {
    if (type === ApprovalType.Payments) {
      cache.modify({
        id: cache.identify({ accountId, __typename: 'Books' }),
        fields: {
          pageClearables: invalidate,
        },
      });
    }

    return Promise.resolve();
  }),
  shapeHandler(
    managerAutopayActivationChangedEvent,
    async ({ detail: { activated, activatedZ, activationUrl, activationStatus } }) => {
      cache.modify({
        id: cache.identify({ accountId, __typename: 'Autopay' }),
        fields: {
          activationStatus: constant(activationStatus),
          activated: constant(activated),
          activatedZ: constant(activatedZ),
          activationUrl: constant(activationUrl),
        },
      });
    }
  ),
  shapeHandler(managerPayeeAutopayActivatedEvent, async ({ detail: { id, payee } }) => {
    const payeeType = capitalize(payee);
    cache.evict({
      id: cache.identify({ id, __typename: payeeType }),
    });
    cache.gc();
    return Promise.resolve();
  }),
  shapeHandler(managerPayeeAutopayCancelledEvent, async ({ detail: { id, payee } }) => {
    const payeeType = capitalize(payee);
    cache.evict({
      id: cache.identify({ id, __typename: payeeType }),
    });
    cache.gc();
    return Promise.resolve();
  }),
  shapeHandler(managerPayeeAutopayFailedEvent, async ({ detail: { id, payee } }) => {
    const payeeType = capitalize(payee);
    cache.evict({
      id: cache.identify({ id, __typename: payeeType }),
    });
    cache.gc();
    return Promise.resolve();
  }),
  shapeHandler(
    managerResidencyResidentPaymentDetailsUpdatedEvent,
    async ({ detail: { residencyId } }) => {
      cache.modify({
        id: cache.identify({ id: residencyId, __typename: 'Residency' }),
        fields: {
          residents: invalidate,
        },
      });
      return Promise.resolve();
    }
  ),
  shapeHandler(
    managerUnitPaymentDetailsUpdatedEvent,
    async ({ detail: { id, paymentDetails } }) => {
      cache.modify({
        id: cache.identify({ id, __typename: 'Unit' }),
        fields: {
          paymentDetails: constant(paymentDetails),
        },
      });
      return Promise.resolve();
    }
  ),
  shapeHandler(
    managerBooksBatchProgressUpdatedEvent,
    async ({ detail: { batchId, taskProgress, failedReason, status, abortedReason } }) => {
      cache.modify({
        id: cache.identify({ id: batchId, __typename: 'BatchTask' }),
        fields: {
          status: constant(status ?? graphqlNull),
          taskProgress: constant({
            label: graphqlNull,
            done: graphqlNull,
            ...taskProgress,
          }),
          failedReason: constant(failedReason ?? graphqlNull),
          abortedReason: constant(abortedReason ?? graphqlNull),
        },
      });

      return Promise.resolve();
    }
  ),
];
