import { eventFactory } from '@propra-system/shape-handler';
import { maybe } from '@propra-system/shapes-util/src/maybe';
import { z } from 'zod';

export const { managerBooksBatchProgressUpdatedEvent } = eventFactory(
  'manager-books-batch-progress-updated-event',
  z.object({
    batchId: z.string(),
    accountId: z.string(),
    taskProgress: z.object({
      total: z.number(),
      processed: z.number(),
      done: maybe(z.boolean()),
      label: maybe(z.string()),
    }),
    failedReason: maybe(z.string()),
    abortedReason: maybe(z.string()),
    status: z.string(),
  })
);
