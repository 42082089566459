import { DateTime } from 'luxon';
import { useEffect, useRef } from 'react';

export const useTimeout = (
  callback: VoidFunction,
  opts?: { delay?: number | string; leading?: boolean }
) => {
  const savedCallback = useRef(callback);
  const intervalRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const { leading } = opts ?? {};
  const delay =
    typeof opts?.delay === 'string'
      ? Math.max(0, DateTime.fromISO(opts.delay).diffNow().as('milliseconds'))
      : opts?.delay;

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (leading) {
      tick();
    }

    if (!delay && delay !== 0) {
      clearTimeout(intervalRef.current);
      return;
    }

    const id = setTimeout(tick, delay);
    intervalRef.current = id;
    return () => clearTimeout(id);
  }, [delay, leading]);
};
