import { OwnerListFieldsFragment } from 'api';
import { AutocompleteField, AutocompleteMultiField } from 'components/Fields';
import { useDebouncedValue } from 'hooks/useDebounce';
import { useServerOwners } from 'hooks/useServerOwners';
import { useState } from 'react';
import { matches, Option } from 'system';

type MultipleProps = {
  selectionMode: 'multiple';
  value?: string[];
  defaultValue?: string[];
  onChange?: (owners?: OwnerListFieldsFragment[]) => void;
  filter?: Partial<Parameters<typeof useServerOwners>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteMultiField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

type SingleProps = {
  selectionMode?: 'single';
  value?: string | Option<string>;
  defaultValue?: string | Option<string>;
  onChange?: (owner?: OwnerListFieldsFragment) => void;
  filter?: Partial<Parameters<typeof useServerOwners>[0]['filter']>;
} & Omit<
  Partial<Parameters<typeof AutocompleteField<string>>[0]>,
  'options' | 'loading' | 'onInputChange' | 'onChange' | 'value' | 'defaultValue'
>;

const isMultiple = matches({ selectionMode: 'multiple' as const });

export type OwnerAutocompleteFieldProps = MultipleProps | SingleProps;

export const OwnerAutocompleteField = ({ filter, ...fieldProps }: OwnerAutocompleteFieldProps) => {
  const [search, setSearch] = useState('');
  const [debouncedSearch, debouncing] = useDebouncedValue(search, { delay: 500 });
  const { loading, owners, ownerOptions } = useServerOwners({
    first: 50,
    filter: {
      ...filter,
      search: debouncedSearch,
    },
  });

  return isMultiple(fieldProps) ? (
    <AutocompleteMultiField
      variant="filled"
      options={ownerOptions}
      loading={loading || debouncing}
      {...fieldProps}
      onInputChange={(_, value) => setSearch(value)}
      onSelected={(newValue) => {
        fieldProps.onChange?.(owners.filter(({ id }) => newValue.includes(id)));
      }}
    />
  ) : (
    <AutocompleteField
      variant="filled"
      options={ownerOptions}
      hideIcons={true}
      loading={loading || debouncing}
      label={fieldProps.label ?? 'Select owner'}
      {...fieldProps}
      onInputChange={(_, value) => setSearch(value)}
      onChange={(_, newValue) => {
        const newId = typeof newValue === 'string' ? newValue : newValue?.id;
        fieldProps.onChange?.(owners.find(({ id: ownerId }) => ownerId === newId));
      }}
    />
  );
};
